<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="2" sm="2">
                <v-text-field
                        v-model="x_vehicle_name"
                        outlined
                        dense
                        label="车辆名称"
                        clearable
                />
            </v-col>
            <v-col cols="12" md="2" sm="2">
                <v-text-field
                        v-model="x_license_plate_nu"
                        outlined
                        dense
                        label="	车牌号"
                        clearable
                />
            </v-col>
            <v-col cols="12" md="2" sm="2">
                <v-autocomplete
                        v-model="x_vehicle_type"
                        :items="typeList"
                        :rules="[vrules.required]"
                        placeholder="车辆类型"
                        dense
                        outlined
                        label="车辆类型"
                >
                    <template v-slot:label>
                        车辆类型
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
        <div style="display:flex;">
            <v-btn  @click="getdata" color="cyan" style="width: 100px;color:#fff;margin-right: 10px">查询</v-btn>
            <v-btn color="primary" class="mb-2" @click="showadd">
                <v-icon dark>mdi-plus</v-icon>
                新增车辆
            </v-btn>
            <v-btn color="success" @click="batch" style="margin-left:10px;">
                <v-icon dark>mdi-arrow-up-circle-outline</v-icon>
                导入车辆
            </v-btn>
        </div>
        <v-card>
            <v-data-table
                    :headers="headers"
                    :items="list"
                    :items-per-page="limit"
                    no-data-text="无数据"
                    hide-default-footer
                    disable-sort
            >
                <template v-slot:item.enable="{ item }">
                    <v-checkbox :input-value="item.enable" hide-details="auto" readonly dense/>
                </template>
                <template v-slot:item.x_state="{ item }">
                  <span style="color:#67C23A" v-if="item.x_state===0">
                       {{x_stateList[item.x_state].cn}}
                  </span>
                    <span style="color:#409EFF" v-else-if="item.x_state===1">
                         {{x_stateList[item.x_state].cn}}
                    </span>
                    <span style="color:#E6A23C" v-else-if="item.x_state===2">
                         {{x_stateList[item.x_state].cn}}
                    </span>
                    <span style="color:#F56C6C" v-else>
                         {{x_stateList[item.x_state].cn}}
                    </span>
                </template>
                <template v-slot:item.username="{ item }">
                    {{ item.username }}
                    <v-chip label small color="green" dark v-if="item.current">当前</v-chip>
                </template>
                <template v-slot:item.role="{ item }">
                    <v-chip label small :color="rolecolors[item.role]" dark>
                        {{ rolenames[item.role] }}
                    </v-chip>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-btn
                            text
                            small
                            color="success"
                            @click="showedit(item)"

                    >
                        编辑
                    </v-btn>
                    <a-popconfirm
                            title="删除车辆"
                            ok-text="确定"
                            cancel-text="取消"

                            @confirm="removeaccount(item.x_vehicle_id)"
                    >
                        <v-btn
                                text
                                small
                                color="error"
                        >
                            删除
                        </v-btn>
                    </a-popconfirm>
                </template>
            </v-data-table>
            <v-pagination
                    v-if="length > 1"
                    v-model="page"
                    :page="page"
                    :length="length"
                    total-visible="10"
            />
        </v-card>
        <v-dialog v-model="model" persistent max-width="800px">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                    <v-card-title
                            class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between"
                    >
                        {{ modeltitle }}
                        <v-icon @click="cancelsave">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                            v-model="accountinfo.x_vehicle_name"
                                            :rules="[vrules.required]"
                                            placeholder="输入车辆名称"

                                            persistent-placeholder
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            车辆名称
                                            <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-select
                                            v-model="accountinfo.x_team_id"
                                            :items="upStreamList"
                                            item-text="x_team_name"
                                            item-value="x_team_id"
                                            dense
                                            outlined
                                            label="请选择车队"
                                            required
                                    >
                                        <template v-slot:label>
                                            所属车队
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="4">
                                    <v-select
                                            v-model="accountinfo.x_driver_id"
                                            :items="driverList"
                                            item-text="x_driver_name"
                                            item-value="x_driver_id"
                                            dense
                                            outlined
                                            label="司机"
                                            required
                                    >
                                        <template v-slot:label>
                                            司机
                                        </template>
                                    </v-select>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4">
                                    <v-autocomplete
                                            v-model="accountinfo.x_vehicle_type"
                                            :items="typeList"
                                            :rules="[vrules.required]"
                                            placeholder="车辆类型"
                                            dense
                                            outlined
                                            label="车辆类型"
                                    >
                                        <template v-slot:label>
                                            车辆类型
                                            <span class="red--text">*</span>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="4">
                                    <v-select
                                            v-model="accountinfo.x_state"
                                            :items="x_stateList"
                                            item-text="cn"
                                            item-value="id"
                                            dense
                                            outlined
                                            label="车辆状态"
                                            required
                                    >
                                        <template v-slot:label>
                                            车辆状态
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                            v-model="accountinfo.x_license_plate_nu"
                                            placeholder="车牌号"
                                            persistent-placeholder
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            车牌号
                                        </template>
                                    </v-text-field>
                                </v-col>

                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                            v-model="accountinfo.x_length"
                                            :rules="[vrules.required]"
                                            placeholder="车长（米）"

                                            persistent-placeholder
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            车长（米）
                                            <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                            v-model="accountinfo.x_compart_length"
                                            placeholder="车辆厢长（米）"

                                            persistent-placeholder
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            车辆厢长（米）
                                            <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                            v-model="accountinfo.x_volume"
                                            placeholder="容积（立方米）"
                                            persistent-placeholder
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            容积（立方米）
                                            <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                            v-model="accountinfo.x_gps"
                                            :rules="[vrules.required]"
                                            placeholder="GPS编码"
                                            persistent-placeholder
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            GPS编码
                                            <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="saveaccount">确定</v-btn>
                        <v-btn @click="cancelsave">关闭</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <v-dialog v-model="model2" persistent max-width="400px">
            <v-form ref="form2" v-model="valid2" lazy-validation>
                <v-card>
                    <v-card-title
                            class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between"
                    >上传文件
                        <v-icon @click="model2=false">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-file-input
                                            v-model="file"
                                            accept=".xls,.xlsx"
                                            label="文件"
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            文件
                                        </template>
                                    </v-file-input>
                                </v-col>
                            </v-row>
                            <v-row style="padding-left: 20px;">
                                <a href="https://dafangsupplychain.oss-cn-zhangjiakou.aliyuncs.com/public/admin_lidi/%E8%BD%A6%E8%BE%86%E4%BF%A1%E6%81%AF%E6%A8%A1%E6%9D%BF.xlsx">下载车辆模板</a>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="saveaccount1">确定</v-btn>
                        <v-btn @click="model2=false">关闭</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-container>
</template>

<script>
    import _ from "underscore";
    import {mapState} from "vuex";
    import axios from "axios";

    export default {
        name: "VehicleList",
        data() {
            return {
                valid: true,
                x_vehicle_type:'',
                x_license_plate_nu:'',
                x_vehicle_name:'',
                typeList: ["--所有--", "分体厢式车", "整体厢式车", "轿车", "客车", "牵引车头", "皮卡车", "分体栏板车", "叉车", "牵引车", "高栏车", "越野车", "半挂车厢", "甩厢", "冷藏车", "运钞车", "未知"],
                x_stateList: [{"cn":"正常使用","id":0},{"cn":"调拨中","id":1},{"cn":"维修中","id":2},{"cn":"封存中","id":3},{"cn":"已报废","id":4},{"cn":"停用","id":5}],
                headers: [
                    {text: "车辆ID", value: "x_vehicle_id",width: 80, align: "center"},
                    {text: "车辆名称", value: "x_vehicle_name",width: 150, align: "center"},
                    {text: "车牌号", value: "x_license_plate_nu",width: 150, align: "center"},
                    {text: "车辆类型", value: "x_vehicle_type",width: 150, align: "center"},
                    {text: "车长（米）   ", value: "x_length",width: 100, align: "center"},
                    {text: "车辆厢长（米）", value: "x_compart_length",width: 150, align: "center"},
                    {text: "容积（立方米）", value: "x_volume",width: 150, align: "center"},
                    {text: "车辆状态", value: "x_state",width: 150, align: "center"},
                    {text: "GPS编码", value: "x_gps",width: 150, align: "center"},
                    {text: "司机名称", value: "x_driver_name", width: 150, align: "center"},
                    {text: "司机手机号", value: "x_driver_phone", width: 150, align: "center"},
                    {text: "所属单位", value: "x_unit", width: 150, align: "center"},
                    {text: "车队名称", value: "x_team_name", width: 150, align: "center"},
                    {text: "车队联系人", value: "x_team_contact",width: 120, align: "center"},
                    {text: "车队联系人手机号", value: "x_team_phone", width: 140, align: "center"},
                    {text: "创建人", value: "create_by", width: 100, align: "center"},
                    {text: "创建时间", value: "create_time",width: 150, align: "center"},
                    { text: "操作", value: "action", width: 200, align: "center" },
                ],
                page: 1,
                limit: 15,
                list: [],
                length: 0,
                model: false,
                modeltitle: "",
                vrules: {
                    required: (value) => !!value || "不能为空",
                    username: (value) =>
                        /[a-zA-Z0-9]{5,12}/.test(value) || "车辆只能包含字母数字，长度5-12位",
                    password: (value) =>
                        (value.length >= 6 && value.length <= 20) || "密码长度6-20位",
                },
                upStreamList: [],
                driverList: [],
                valid2: true,
                model2: false,
                file:null,
                fileUrl:'',
                accountinfo: {
                    x_vehicle_id: '',
                    x_team_id: "",
                    x_driver_id: "",
                    x_vehicle_name: '',
                    x_license_plate_nu: '',
                    x_vehicle_type: '',
                    x_length: '',
                    x_compart_length: '',
                    x_volume: '',
                    x_gps: '',
                    x_state: 0,
                },

            };
        },
        computed: {
            ...mapState(["role", "userid", "roles", "roles2", "rolecolors", "rolenames"]),
        },
        components: {},
        watch: {
            page() {
                this.getdata();
            },
        },
        created() {
            this.$api
                .doAPI({
                    Action: "ldadmin_teamlist",
                    Data: {
                        page: 1,
                        limit: 100,
                    },
                })
                .then((res) => {
                    this.$toast.clear();
                    if (res.code === 0) {
                        this.upStreamList = res.data.list;
                    } else {
                        this.$utils.alert(res.msg);
                    }
                })
            this.$api
                .doAPI({
                    Action: "ldadmin_driverlist",
                    Data: {
                        page: 1,
                        limit: 100,
                    },
                })
                .then((res) => {
                    this.$toast.clear();
                    if (res.code === 0) {
                        this.driverList = res.data.list;
                    } else {
                        this.$utils.alert(res.msg);
                    }
                })
        },
        activated() {
            this.page = 1;
            this.length = 0;
            this.getdata();
        },
        methods: {
            batch(){
                this.model2=true
                this.fileUrl=null
                this.file=null
            },
            saveaccount1() {
                let formData = new FormData();
                formData.append('file', this.file);
                formData.append('Action', 'ldadmin_vehicleimport');
                formData.append('filetype', 'excel');
                this.$api
                    .post('/api/vehicleimport',formData)
                    .then((res) => {
                        this.$toast.clear();
                        if (res.code === 0) {
                            this.model2 = false;
                            this.getdata();
                        } else {
                            this.$utils.alert(res.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.clear();
                        console.error(err);
                    });
            },
            getdata() {
                this.$toast.loading({message: ""});
                this.$api
                    .doAPI({
                        Action: "ldadmin_vehiclelist",
                        Data: {
                            page: this.page,
                            limit: this.limit,
                            x_vehicle_name: this.x_vehicle_name,
                            x_license_plate_nu: this.x_license_plate_nu,
                            x_vehicle_type: this.x_vehicle_type==='--所有--'?'':this.x_vehicle_type
                        },
                    })
                    .then((res) => {
                        this.$toast.clear();
                        if (res.code === 0) {
                            this.list = res.data.list;
                            this.length = Number((res.data.count/this.limit).toFixed(0))+1;
                            if (this.length > 0 && this.page > this.length) this.page = 1;
                        } else {
                            this.$utils.alert(res.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.clear();
                        console.error(err);
                    });
            },
            showadd() {
                this.accountinfo = {
                    x_vehicle_id: '',
                    x_team_id: "",
                    x_driver_id: "",
                    x_vehicle_name: '',
                    x_license_plate_nu: '',
                    x_vehicle_type: '',
                    x_length: '',
                    x_compart_length: '',
                    x_volume: '',
                    x_gps: '',
                    x_state: 0,
                };
                this.modeltitle = "新增车辆";
                this.model = true;
            },
            showedit(item) {
                this.accountinfo = _.pick(item, _.keys(this.accountinfo));
                this.modeltitle = "编辑车辆";
                this.model = true;
            },
            saveaccount() {
                if (!this.$refs.form.validate()) return;
                if (this.accountinfo.x_vehicle_id) {
                    this.$toast.loading({message: ""});
                    let info=JSON.parse(JSON.stringify(this.accountinfo))
                    info.x_vehicle_type=info.x_vehicle_type==='--所有--'?'':info.x_vehicle_type
                    this.$api
                        .doAPI({
                            Action: "ldadmin_vehicleedit",
                            Data: {...info},
                        })
                        .then((res) => {
                            this.$toast.clear();
                            if (res.code === 0) {
                                this.model = false;
                                this.getdata();
                            } else {
                                this.$utils.alert(res.msg);
                            }
                        })
                        .catch((err) => {
                            this.$toast.clear();
                            console.error(err);
                        });
                } else {
                    this.$toast.loading({message: ""});
                    let info=JSON.parse(JSON.stringify(this.accountinfo))
                    info.x_vehicle_type=info.x_vehicle_type==='--所有--'?'':info.x_vehicle_type
                    this.$api
                        .doAPI({
                            Action: "ldadmin_vehicleadd",
                            Data: {...info},
                        })
                        .then((res) => {
                            this.$toast.clear();
                            if (res.code === 0) {
                                this.model = false;
                                this.getdata();
                            } else {
                                this.$utils.alert(res.msg);
                            }
                        })
                        .catch((err) => {
                            this.$toast.clear();
                            console.error(err);
                        });
                }
            },
            cancelsave() {
                this.$refs.form.resetValidation();
                this.model = false;
            },
            removeaccount(id) {
                this.$toast.loading({message: ""});
                this.$api
                    .doAPI({
                        Action: "ldadmin_vehicledel",
                        Data: {x_vehicle_id: id},
                    })
                    .then((res) => {
                        this.$toast.clear();
                        if (res.code === 0) {
                            this.getdata();
                        } else {
                            this.$utils.alert(res.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.clear();
                        console.error(err);
                    });
            },
        },
    };
</script>
<style lang="less" scoped>
    /deep/ .row {
        margin-bottom: 0 !important;
    }

    /deep/ .col-12 {
        padding-bottom: 0 !important;
    }

    /deep/ .v-input--selection-controls {
        margin-top: 0;
        padding-top: 0;
    }

    /deep/ .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        padding: 0 4px;
    }
</style>

<template>
    <v-container fluid>
        <!--        <v-card>-->
        <!--            <v-card-title>-->
        <v-row>
            <v-col cols="12" md="2" sm="2">
                <v-select
                        v-model="x_oil_name"
                        :items="oillist"
                        item-text="x_oil_name"
                        item-value="x_oil_id"
                        dense
                        outlined
                        label="请选择邮路"
                        clearable
                        required
                >
                    <template v-slot:label>
                        邮路
                    </template>
                </v-select>
            </v-col>
            <v-col cols="12" md="2" sm="2">
                <v-text-field
                        v-model="x_belong_bureau"
                        outlined
                        dense
                        label="邮局"
                        clearable
                />
            </v-col>
            <v-col cols="12" md="2" sm="2">
                <v-text-field
                        v-model="x_license_plate_nu"
                        outlined
                        dense
                        label="车牌号"
                        clearable
                        style="padding-right: 20px;"
                />

            </v-col>
            <v-col cols="12" md="2" sm="2" class="d-flex">
                <v-text-field
                        v-model="x_send_no"
                        outlined
                        dense
                        label="派车单号"
                        clearable
                        style="padding-right: 20px;"
                />
            </v-col>
            <v-col cols="2">
                <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="start_arr_date"
                        transition="scale-transition"
                        min-width="auto"
                        offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                                v-model="start_arr_date"
                                label="运单开始时间"
                                placeholder="运单开始时间"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dense
                                outlined
                                clearable
                        >
                            <template v-slot:label> 运单开始时间</template>
                        </v-text-field>
                    </template>
                    <v-date-picker
                            v-model="start_arr_date"
                            no-title
                            value="YYYY-mm-dd"
                            scrollable
                            locale="zh-cn"
                            @input="
                    $refs.menu.save(start_arr_date);
                    menu = false;
                  "
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="2">
                <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :return-value.sync="end_arr_date"
                        transition="scale-transition"
                        min-width="auto"
                        offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                                v-model="end_arr_date"
                                label="运单到达时间"
                                placeholder="运单到达时间"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dense
                                outlined
                                clearable
                        >
                            <template v-slot:label> 运单到达时间</template>
                        </v-text-field>
                    </template>
                    <v-date-picker
                            v-model="end_arr_date"
                            no-title
                            value="YYYY-mm-dd"
                            scrollable
                            locale="zh-cn"
                            @input="
                    $refs.menu2.save(end_arr_date);
                    menu2 = false;
                  "
                    ></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
<!--        <v-row>-->
<!--            <v-col cols="2">-->
<!--                <v-menu-->
<!--                        ref="menu3"-->
<!--                        v-model="menu3"-->
<!--                        :close-on-content-click="false"-->
<!--                        :return-value.sync="start_dep_date"-->
<!--                        transition="scale-transition"-->
<!--                        min-width="auto"-->
<!--                        offset-y-->
<!--                >-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
<!--                        <v-text-field-->
<!--                                v-model="start_dep_date"-->
<!--                                label="发车开始时间"-->
<!--                                placeholder="发车开始时间"-->
<!--                                append-icon="mdi-calendar"-->
<!--                                readonly-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                                dense-->
<!--                                outlined-->
<!--                                clearable-->
<!--                        >-->
<!--                            <template v-slot:label> 发车开始时间</template>-->
<!--                        </v-text-field>-->
<!--                    </template>-->
<!--                    <v-date-picker-->
<!--                            v-model="start_dep_date"-->
<!--                            no-title-->
<!--                            value="YYYY-mm-dd"-->
<!--                            scrollable-->
<!--                            locale="zh-cn"-->
<!--                            @input="-->
<!--                    $refs.menu3.save(start_dep_date);-->
<!--                    menu3 = false;-->
<!--                  "-->
<!--                    ></v-date-picker>-->
<!--                </v-menu>-->
<!--            </v-col>-->
<!--            <v-col cols="2">-->
<!--                <v-menu-->
<!--                        ref="menu4"-->
<!--                        v-model="menu4"-->
<!--                        :close-on-content-click="false"-->
<!--                        :return-value.sync="end_dep_date"-->
<!--                        transition="scale-transition"-->
<!--                        min-width="auto"-->
<!--                        offset-y-->
<!--                >-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
<!--                        <v-text-field-->
<!--                                v-model="end_dep_date"-->
<!--                                label="	发车截止时间"-->
<!--                                placeholder="	发车截止时间"-->
<!--                                append-icon="mdi-calendar"-->
<!--                                readonly-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                                dense-->
<!--                                outlined-->
<!--                                clearable-->
<!--                        >-->
<!--                            <template v-slot:label> 发车截止时间</template>-->
<!--                        </v-text-field>-->
<!--                    </template>-->
<!--                    <v-date-picker-->
<!--                            v-model="end_dep_date"-->
<!--                            no-title-->
<!--                            value="YYYY-mm-dd"-->
<!--                            scrollable-->
<!--                            locale="zh-cn"-->
<!--                            @input="-->
<!--                    $refs.menu4.save(end_dep_date);-->
<!--                    menu4 = false;-->
<!--                  "-->
<!--                    ></v-date-picker>-->
<!--                </v-menu>-->
<!--            </v-col>-->
<!--            <v-col cols="2">-->
<!--                <v-menu-->
<!--                        ref="menu5"-->
<!--                        v-model="menu5"-->
<!--                        :close-on-content-click="false"-->
<!--                        :return-value.sync="start_arr_date"-->
<!--                        transition="scale-transition"-->
<!--                        min-width="auto"-->
<!--                        offset-y-->
<!--                >-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
<!--                        <v-text-field-->
<!--                                v-model="start_arr_date"-->
<!--                                label="	到达开始时间"-->
<!--                                placeholder="	到达开始时间"-->
<!--                                append-icon="mdi-calendar"-->
<!--                                readonly-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                                dense-->
<!--                                outlined-->
<!--                                clearable-->
<!--                        >-->
<!--                            <template v-slot:label>到达开始时间</template>-->
<!--                        </v-text-field>-->
<!--                    </template>-->
<!--                    <v-date-picker-->
<!--                            v-model="start_arr_date"-->
<!--                            no-title-->
<!--                            value="YYYY-mm-dd"-->
<!--                            scrollable-->
<!--                            locale="zh-cn"-->
<!--                            @input="-->
<!--                    $refs.menu5.save(start_arr_date);-->
<!--                    menu5 = false;-->
<!--                  "-->
<!--                    ></v-date-picker>-->
<!--                </v-menu>-->
<!--            </v-col>-->
<!--            <v-col cols="2">-->
<!--                <v-menu-->
<!--                        ref="menu6"-->
<!--                        v-model="menu6"-->
<!--                        :close-on-content-click="false"-->
<!--                        :return-value.sync="end_arr_date"-->
<!--                        transition="scale-transition"-->
<!--                        min-width="auto"-->
<!--                        offset-y-->
<!--                >-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
<!--                        <v-text-field-->
<!--                                v-model="end_arr_date"-->
<!--                                label="	到达截止时间"-->
<!--                                placeholder="	到达截止时间"-->
<!--                                append-icon="mdi-calendar"-->
<!--                                readonly-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                                dense-->
<!--                                outlined-->
<!--                                clearable-->
<!--                        >-->
<!--                            <template v-slot:label>到达截止时间</template>-->
<!--                        </v-text-field>-->
<!--                    </template>-->
<!--                    <v-date-picker-->
<!--                            v-model="end_arr_date"-->
<!--                            no-title-->
<!--                            value="YYYY-mm-dd"-->
<!--                            scrollable-->
<!--                            locale="zh-cn"-->
<!--                            @input="-->
<!--                    $refs.menu6.save(end_arr_date);-->
<!--                    menu6 = false;-->
<!--                  "-->
<!--                    ></v-date-picker>-->
<!--                </v-menu>-->
<!--            </v-col>-->
<!--        </v-row>-->
        <!--            </v-card-title>-->
        <!--            <v-card-title>-->
        <v-row style="padding:0px 20px;padding-bottom: 20px">
            <v-btn @click="getdata" color="cyan" style="width: 100px;color:#fff">查询</v-btn>
            <v-btn color="primary" style="margin-left:10px;" @click="showadd">
                <v-icon dark>mdi-plus</v-icon>
                新增运单
            </v-btn>
            <v-btn color="success" @click="batch" style="margin-left:10px;">
                <v-icon dark>mdi-arrow-up-circle-outline</v-icon>
                导入运单
            </v-btn>
            <v-btn color="indigo" @click="down" style="margin-left:10px;color:#fff">
                <v-icon dark>mdi-arrow-down-circle-outline</v-icon>
                下载运单报表
            </v-btn>
        </v-row>
        <!--            </v-card-title>-->
        <!--        </v-card>-->
        <v-card>
            <v-data-table
                    :headers="headers"
                    :items="list"
                    :items-per-page="limit"
                    no-data-text="无数据"
                    hide-default-footer
                    disable-sort
            >
                <template v-slot:item.x_state="{ item }">
                    <span style="color:red" v-if="item.x_state">
                        禁用
                    </span>
                    <span style="color:#01579B" v-else>
                        正常
                    </span>

                </template>
                <template v-slot:item.action="{ item }">
                    <div style="display:flex;justify-content: flex-start">

                        <v-btn
                                text
                                small
                                color="success"
                                @click="showedit(item)"
                        >
                            编辑
                        </v-btn>
                        <a-popconfirm
                                title="删除运单"
                                ok-text="确定"
                                cancel-text="取消"
                                @confirm="removeaccount(item.x_waybill_uuid)"
                        >
                            <v-btn
                                    text
                                    small
                                    color="error"
                            >
                                删除
                            </v-btn>
                        </a-popconfirm>
                        <!--                        <v-btn-->
                        <!--                                text-->
                        <!--                                small-->
                        <!--                                color="primary"-->
                        <!--                                @click="showStatus(item.x_waybill_uuid)"-->
                        <!--                                v-if="-->
                        <!--                      item.x_state-->
                        <!--                    ">-->
                        <!--                            启用-->
                        <!--                        </v-btn>-->

                    </div>
                </template>
            </v-data-table>
            <v-pagination
                    v-if="length > 1"
                    v-model="page"
                    :page="page"
                    :length="length"
                    total-visible="10"
            />
        </v-card>
        <v-dialog v-model="model2" persistent max-width="400px">
            <v-form ref="form2" v-model="valid2" lazy-validation>
                <v-card>
                    <v-card-title
                            class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between"
                    >上传文件
                        <v-icon @click="model2=false">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-file-input
                                            v-model="file"
                                            accept=".xls,.xlsx"
                                            label="文件"
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            文件
                                        </template>
                                    </v-file-input>
                                </v-col>
                            </v-row>
                            <v-row style="padding-left: 20px;">
                                <a href="https://dafangsupplychain.oss-cn-zhangjiakou.aliyuncs.com/public/admin_lidi/%E8%BF%90%E5%8D%95%E4%BF%A1%E6%81%AF%E6%A8%A1%E6%9D%BF.xls">下载运单模板</a>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="saveaccount1">确定</v-btn>
                        <v-btn @click="model2=false">关闭</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <v-dialog v-model="model" persistent max-width="800px">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                    <v-card-title
                            class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between"
                    >
                        {{ modeltitle }}
                        <v-icon @click="cancelsave">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                            v-model="accountinfo.x_waybill_no"
                                            :rules="[vrules.required]"
                                            placeholder="运单流水号"
                                            persistent-placeholder
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            运单流水号
                                            <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                            v-model="accountinfo.x_send_no"
                                            :rules="[vrules.required]"
                                            placeholder="派车单号"
                                            persistent-placeholder
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            派车单号
                                            <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                            v-model="accountinfo.x_shift"
                                            :rules="[vrules.required]"
                                            placeholder="班次"

                                            persistent-placeholder
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            班次
                                            <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <v-select
                                            v-model="accountinfo.x_oil_id"
                                            :items="oillist"
                                            item-text="x_oil_name"
                                            item-value="x_oil_id"
                                            :rules="[v => !!v || '请选择类型']"
                                            dense
                                            outlined
                                            label="请选择邮路"
                                            required
                                    >
                                        <template v-slot:label>
                                            邮路
                                            <span class="red--text">*</span>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="4">
                                    <v-select
                                            v-model="accountinfo.x_vehicle_id"
                                            :items="teamlist"
                                            item-text="x_license_plate_nu"
                                            item-value="x_vehicle_id"
                                            :rules="[v => !!v || '请选择类型']"
                                            dense
                                            outlined
                                            label="请选择车辆"
                                            required
                                    >
                                        <template v-slot:label>
                                            车辆
                                            <span class="red--text">*</span>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                            v-model="accountinfo.x_freight"
                                            placeholder="运费"
                                            persistent-placeholder
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            运费
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                            v-model="accountinfo.x_traffic"
                                            placeholder="运输量"
                                            persistent-placeholder
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            运输量
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                            v-model="accountinfo.x_run_time"
                                            placeholder="运行时长"
                                            persistent-placeholder
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            运行时长
                                        </template>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="4">
                                    <v-menu
                                            ref="menu01"
                                            v-model="menu01"
                                            :close-on-content-click="false"
                                            :return-value.sync="accountinfo.x_approach_time"
                                            transition="scale-transition"
                                            min-width="auto"
                                            offset-y
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                    v-model="accountinfo.x_approach_time"
                                                    label="	进场时间"
                                                    placeholder="进场时间"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    dense
                                                    outlined
                                                    clearable
                                            >
                                                <template v-slot:label>进场时间</template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                                v-model="accountinfo.x_approach_time"
                                                no-title
                                                value="YYYY-mm-dd"
                                                scrollable
                                                locale="zh-cn"
                                                @input="
                                                $refs.menu01.save(accountinfo.x_approach_time);
                                                menu01 = false;
                                              "
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="4">
                                    <v-menu
                                            ref="menu04"
                                            v-model="menu04"
                                            :close-on-content-click="false"
                                            :return-value.sync="accountinfo.x_departure_time"
                                            transition="scale-transition"
                                            min-width="auto"
                                            offset-y
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                    v-model="accountinfo.x_departure_time"
                                                    label="	发车时间"
                                                    placeholder="发车时间"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    dense
                                                    outlined
                                                    clearable
                                            >
                                                <template v-slot:label>发车时间</template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                                v-model="accountinfo.x_departure_time"
                                                no-title
                                                value="YYYY-mm-dd"
                                                scrollable
                                                locale="zh-cn"
                                                @input="
                                                    $refs.menu04.save(accountinfo.x_departure_time);
                                                    menu04 = false;
                                                  "
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="4">
                                    <v-menu
                                            ref="menu02"
                                            v-model="menu02"
                                            :close-on-content-click="false"
                                            :return-value.sync="accountinfo.x_arrive_time"
                                            transition="scale-transition"
                                            min-width="auto"
                                            offset-y
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                    v-model="accountinfo.x_arrive_time"
                                                    label="	到达时间"
                                                    placeholder="到达时间"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    dense
                                                    outlined
                                                    clearable
                                            >
                                                <template v-slot:label>到达时间</template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                                v-model="accountinfo.x_arrive_time"
                                                no-title
                                                value="YYYY-mm-dd"
                                                scrollable
                                                locale="zh-cn"
                                                @input="
                                                $refs.menu02.save(accountinfo.x_arrive_time);
                                                menu02 = false;
                                              "
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="4">
                                    <v-menu
                                            ref="menu03"
                                            v-model="menu03"
                                            :close-on-content-click="false"
                                            :return-value.sync="accountinfo.x_waybill_no_time"
                                            transition="scale-transition"
                                            min-width="auto"
                                            offset-y
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                    v-model="accountinfo.x_waybill_no_time"
                                                    label="	运单流水号日期"
                                                    placeholder="运单流水号日期"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    dense
                                                    outlined
                                                    clearable
                                            >
                                                <template v-slot:label>运单流水号日期</template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                                v-model="accountinfo.x_waybill_no_time"
                                                no-title
                                                value="YYYY-mm-dd"
                                                scrollable
                                                locale="zh-cn"
                                                @input="
                                                $refs.menu03.save(accountinfo.x_waybill_no_time);
                                                menu03 = false;
                                              "
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="saveaccount">确定</v-btn>
                        <v-btn @click="cancelsave">关闭</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-container>
</template>

<script>
    import _ from "underscore";
    import {mapState} from "vuex";
    import axios from "axios";

    export default {
        name: "OrderList",
        data() {
            return {
                oillist: [],
                teamlist: [],
                valid: true,
                valid2: true,
                headers: [
                    {text: "运单ID", value: "x_waybill_id", width: 80, align: "center"},
                    {text: "运单流水号", value: "x_waybill_no", width: 150, align: "center"},
                    {text: "派车单号", value: "x_send_no", width: 120, align: "center"},
                    {text: "邮路", value: "x_oil_name", width: 120, align: "center"},
                    {text: "邮局", value: "x_post_office", width: 120, align: "center"},
                    {text: "车队名称", value: "x_team_name", width: 120, align: "center"},
                    {text: "车牌号", value: "x_license_plate_nu", width: 120, align: "center"},
                    {text: "车长", value: "x_length", width: 80, align: "center"},
                    {text: "班次", value: "x_shift", width: 80, align: "center"},
                    {text: "进场时间", value: "x_approach_time", width: 200, align: "center"},
                    {text: "发车时间", value: "x_departure_time", width: 200, align: "center"},
                    {text: "到达时间", value: "x_arrive_time", width: 200, align: "center"},
                    {text: "运行时长", value: "x_run_time", width: 100, align: "center"},
                    {text: "运输量", value: "x_traffic", width: 100, align: "center"},
                    {text: "运费", value: "x_freight", width: 100, align: "center"},
                    {text: "流水号日期", value: "x_waybill_no_time", width: 100, align: "center"},
                    {text: "状态", value: "x_state", width: 100, align: "center"},
                    {text: "创建人", value: "create_by", width: 100, align: "center"},
                    {text: "创建时间", value: "create_time", width: 200, align: "center"},
                    {text: "修改人", value: "update_by", width: 100, align: "center"},
                    {text: "修改时间", value: "update_time", width: 200, align: "center"},
                    {text: "操作", value: "action", width: 180, align: "center"},
                ],
                page: 1,
                limit: 15,
                list: [],
                length: 0,
                model: false,
                modeltitle: "",
                file: '',
                vrules: {
                    required: (value) => !!value || "不能为空",
                    username: (value) =>
                        /[a-zA-Z0-9]{5,12}/.test(value) || "邮路只能包含字母数字，长度5-12位",
                    password: (value) =>
                        (value.length >= 6 && value.length <= 20) || "密码长度6-20位",
                },
                accountinfo: {
                    x_waybill_uuid: '',
                    x_waybill_no: '',
                    x_send_no: '',
                    x_oil_id: '',
                    x_vehicle_id: '',
                    x_shift: '',
                    x_approach_time: '',
                    x_departure_time: '',
                    x_arrive_time: '',
                    x_run_time: '',
                    x_traffic: '',
                    x_freight: '',
                    x_waybill_no_time: '',
                },
                model2: false,
                menu: false,
                menu2: false,
                menu3: false,
                menu4: false,
                menu5: false,
                menu6: false,
                menu01: false,
                menu02: false,
                menu03: false,
                menu04: false,
                x_oil_name: '',
                x_license_plate_nu: '',
                // start_way_date: '',
                // end_way_date: '',
                // start_dep_date: '',
                // end_dep_date: '',
                start_arr_date: '',
                end_arr_date: '',
                x_send_no: '',
                x_team_name: '',
                x_belong_bureau: '',
                fileUrl: ''
            };
        },
        computed: {
            ...mapState(["role", "userid", "roles", "roles2", "rolecolors", "rolenames"]),
        },
        components: {},
        watch: {
            page() {
                this.getdata();
            },
        },
        created() {
            this.$api
                .doAPI({
                    Action: "ldadmin_oillist",
                    Data: {
                        page: 1,
                        limit: 100,
                    },
                })
                .then((res) => {
                    this.$toast.clear();
                    if (res.code === 0) {
                        this.oillist = res.data.list;
                    } else {
                        this.$utils.alert(res.msg);
                    }
                })
            this.$api
                .doAPI({
                    Action: "ldadmin_vehiclelist",
                    Data: {
                        page: 1,
                        limit: 100,
                    },
                })
                .then((res) => {
                    this.$toast.clear();
                    if (res.code === 0) {
                        this.teamlist = res.data.list;
                    } else {
                        this.$utils.alert(res.msg);
                    }
                })
        },
        activated() {
            this.page = 1;
            this.list = []
            this.length = 0;
            this.getdata();
        },
        methods: {
            batch() {
                this.model2 = true
                this.fileUrl = null
                this.file = null
            },
            saveaccount1() {
                let formData = new FormData();
                formData.append('file', this.file);
                formData.append('Action', 'ldadmin_waybillimport');
                formData.append('filetype', 'excel');
                this.$api
                    .post('/api/waybillimport', formData)
                    .then((res) => {
                        this.$toast.clear();
                        if (res.code === 0) {
                            this.model2 = false;
                            this.getdata();
                        } else {
                            this.$utils.alert(res.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.clear();
                        console.error(err);
                    });
            },
            getdata() {

                this.$toast.loading({message: ""});
                this.$api
                    .doAPI({
                        Action: "ldadmin_waybilllist",
                        Data: {
                            page: this.page, limit: this.limit,
                            x_oil_name: this.x_oil_name,
                            x_license_plate_nu: this.x_license_plate_nu,
                            // start_way_date: this.start_way_date,
                            // end_way_date: this.end_way_date,
                            // start_dep_date: this.start_dep_date,
                            // end_dep_date: this.end_dep_date,
                            start_arr_date: this.start_arr_date,
                            end_arr_date: this.end_arr_date,
                            x_send_no: this.x_send_no,
                            // x_team_name: this.x_team_name,
                            x_belong_bureau: this.x_belong_bureau,
                        },
                    })
                    .then((res) => {
                        this.$toast.clear();
                        if (res.code === 0) {
                            this.list = res.data.list;
                            this.length = Number((res.data.count / this.limit).toFixed(0)) + 1;
                            if (this.length > 0 && this.page > this.length) this.page = 1;
                        } else {
                            this.$utils.alert(res.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.clear();
                        console.error(err);
                    });
            },
            download(url, name = '') {
                this.getBlob(url).then((blob) => {
                    this.saveAs(blob, name);
                })
            },
            getBlob(url) {
                return new Promise((resolve, reject) => {
                    axios({
                        method: 'get',
                        url,
                        responseType: 'blob',
                        headers:{
                            'Authorization':getToken()
                        }
                    }).then(data => {
                        resolve(data.data);
                    }).catch(error => {
                        reject(error.toString());
                    })
                    // esbdownload(url).then(data => {
                    //   resolve(data);
                    // }).catch(error => {
                    //   reject(error.toString());
                    // })
                })
            },
            saveAs(blob, name) {
                if (window.msSaveOrOpenBlob) {
                    navigator.msSaveBlob(blob, name);
                } else {
                    const link = document.createElement('a');
                    const body = document.querySelector('body');
                    // link.href = window.URL.createObjectURL(blob); // 创建对象url
                    // console.log(blob)
                    // link.href = window.URL.createObjectURL(new Blob(blob)); // 创建对象url
                    let url = null
                    const binaryData = [];
                    binaryData.push(blob);
                    link.href = window.URL.createObjectURL(new Blob(binaryData,{type:'application/vnd.ms-excel'}));
                    link.download = name;
                    // fix Firefox
                    link.style.display = 'none';
                    body.appendChild(link);
                    link.click();
                    body.removeChild(link);
                    window.URL.revokeObjectURL(link.href); // 通过调用 URL.createObjectURL() 创建的 URL 对象
                }
            },
            down(){
                this.$api
                    .post('/api/waybilldownload',{
                        Action: "ldadmin_waybilldownload",
                        Data: {
                            x_oil_name: this.x_oil_name,
                            x_license_plate_nu: this.x_license_plate_nu,
                            start_arr_date: this.start_arr_date,
                            end_arr_date: this.end_arr_date,
                            x_send_no: this.x_send_no,
                            x_team_name: this.x_team_name,
                            x_belong_bureau: this.x_belong_bureau,
                        },
                    },{responseType: 'blob'})
                    .then((res) => {
                        this.saveAs(res, '运单报表数据')
                    })
                    .catch((err) => {
                        this.$toast.clear();
                        console.error(err);
                    });
            },
            showadd() {
                // this.accountinfo = {
                //     x_waybill_uuid:'',
                //     x_waybill_no: '',
                //     x_send_no: '',
                //     x_oil_id: '',
                //     x_vehicle_id: '',
                //     x_shift: '',
                //     x_approach_time: '',
                //     x_departure_time: '',
                //     x_arrive_time: '',
                //     x_run_time: '',
                //     x_traffic: '',
                //     x_freight: '',
                //     x_waybill_no_time: '',
                // };
                this.$router.push({path: '/orders/create', query: {id: 0}})
                // this.modeltitle = "新增运单";
                // this.model = true;
            },
            showedit(item) {
                // this.$router.push({name: 'addClue', params: {type: 1} })
                this.$router.push({path: '/orders/create', query: {id: item.x_waybill_uuid}})
                // this.accountinfo = _.pick(item, _.keys(this.accountinfo));
                // this.modeltitle = "编辑运单";
                // this.model = true;
            },
            saveaccount() {
                if (!this.$refs.form.validate()) return;
                if (this.accountinfo.x_waybill_uuid) {
                    this.$toast.loading({message: ""});
                    this.$api
                        .doAPI({
                            Action: "ldadmin_waybilledit",
                            Data: {...this.accountinfo},
                        })
                        .then((res) => {
                            this.$toast.clear();
                            if (res.code === 0) {
                                this.model = false;
                                this.getdata();
                            } else {
                                this.$utils.alert(res.msg);
                            }
                        })
                        .catch((err) => {
                            this.$toast.clear();
                            console.error(err);
                        });
                } else {
                    this.$toast.loading({message: ""});
                    this.$api
                        .doAPI({
                            Action: "ldadmin_waybilladd",
                            Data: {...this.accountinfo},
                        })
                        .then((res) => {
                            this.$toast.clear();
                            if (res.code === 0) {
                                this.model = false;
                                this.getdata();
                            } else {
                                this.$utils.alert(res.msg);
                            }
                        })
                        .catch((err) => {
                            this.$toast.clear();
                            console.error(err);
                        });
                }

            },
            cancelsave() {
                this.$refs.form.resetValidation();
                this.model = false;
            },
            showStatus(id) {
                this.$toast.loading({message: ""});
                this.$api
                    .doAPI({
                        Action: "ldadmin_oilen",
                        Data: {x_oil_id: id},
                    })
                    .then((res) => {
                        this.$toast.clear();
                        if (res.code === 0) {
                            this.getdata();
                        } else {
                            this.$utils.alert(res.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.clear();
                        console.error(err);
                    });
            },
            removeaccount(id) {
                this.$toast.loading({message: ""});
                this.$api
                    .doAPI({
                        Action: "ldadmin_waybilldel",
                        Data: {x_waybill_uuid: id},
                    })
                    .then((res) => {
                        this.$toast.clear();
                        if (res.code === 0) {
                            this.getdata();
                        } else {
                            this.$utils.alert(res.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.clear();
                        console.error(err);
                    });
            },
        },
    };
</script>
<style lang="less" scoped>
    /deep/ .row {
        margin-bottom: 0 !important;
    }

    /deep/ .col-12 {
        padding-bottom: 0 !important;
    }

    /deep/ .v-input--selection-controls {
        margin-top: 0;
        padding-top: 0;
    }

    /deep/ .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        padding: 0 4px;
    }
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import BasicLayout from './layouts/BasicLayout'
import EmptyLayout from './layouts/EmptyLayout'
Vue.component('basic-layout', BasicLayout);
Vue.component('empty-layout', EmptyLayout);
import api from './request'
Vue.prototype.$api = api;
import utils from './utils';
Vue.prototype.$utils = utils;
import async from 'async';
Vue.prototype.$async = async;
import axios from 'axios'
Vue.prototype.$axios = axios;

Vue.filter('dateFormat', function (date, pattern = "YYYY-MM-DD") {
  return utils.dateTime(date, pattern);
});
Vue.filter('dateTime', function (date, pattern = "YYYY-MM-DD") {
  return utils.dateTime(date, pattern);
});
Vue.filter('fromNow', function (date) {
  return utils.fromNow(date);
});

import { Dialog, Empty, Toast, Lazyload, } from "vant";
Vue.use(Empty);
Vue.use(Dialog);
Toast.setDefaultOptions('loading', { duration: 0, forbidClick: true, loadingType: "spinner" });
Vue.use(Toast);
Vue.use(Lazyload, { lazyComponent: true });

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

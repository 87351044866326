<template>
  <v-app id="Roles">
    <v-container fluid>
      <v-row style="padding:10px 0;">
        <v-col cols="12" md="12" sm="12">
          <v-btn color="primary" @click="dialog = true">
            <v-icon dark>mdi-plus</v-icon>新增角色
          </v-btn>
        </v-col>
      </v-row>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="list"
          :items-per-page="limit"
          class="elevation-1"
          no-data-text="无数据"
          hide-default-footer
          disable-sort
        ></v-data-table>
        <v-pagination
          v-if="length > 1"
          v-model="page"
          :page="page"
          :length="length"
          total-visible="10"
        />
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "Contract",
  data() {
    return {
      headers: [
        
      ],
      list: [],
      dialog:false,
      length:0
    };
  },
  methods: {}
};
</script>
<style lang="less" scoped>
</style>

import { v4 as uuidv4 } from 'uuid';
import { Base64 } from 'js-base64';
import moment from 'moment';
moment.locale('zh_cn');
import _ from "underscore";
import axios from 'axios';
import { Dialog, Toast } from "vant";
import Decimal from 'decimal.js';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { cn as nzhcn } from "nzh";
import store from './store';

function sliceFile({ name, size }) {
  let chunksize = 5 * 1024 * 1024;//文件切割最小5M
  let firstchunksize = 1024;//第一个数据包大小
  let res = [];
  if (size <= chunksize) {
    if (size <= firstchunksize) {
      res.push({ chunk: 0, chunks: 1, name });
    } else {
      let chunks = 2;
      res.push({ chunk: 0, chunks, name, from: 0, to: firstchunksize });
      res.push({ chunk: 1, chunks, name, from: firstchunksize, to: size });
    }
  } else {
    let chunks = (size - firstchunksize) % chunksize == 0 ? Math.floor((size - firstchunksize) / chunksize) : Math.floor((size - firstchunksize) / chunksize) + 1;
    let tchunks = chunks + 1;
    res.push({ chunk: 0, chunks: tchunks, name, from: 0, to: firstchunksize });
    for (let i = 0; i < chunks; i++) {
      res.push({ chunk: i + 1, chunks: tchunks, name, from: (i * chunksize) + firstchunksize, to: (i == chunks - 1) ? size : (i + 1) * chunksize + firstchunksize });
    }
  }
  // console.log(res);
  return res;
}
function makeUUID() {
  return uuidv4().replace(/-/g, '');
}
function dateTime(date, pattern = "YYYY-MM-DD") {
  date = moment(date);
  if (date.isValid()) return date.format(pattern);
  return "/";
}
function fromNow(date) {
  return moment(date).fromNow();
}
function callPhone(phone) {
  let a = document.createElement("a"), //创建a标签
    e = document.createEvent("MouseEvents"); //创建鼠标事件对象
  e.initEvent("click", false, false); //初始化事件对象
  a.href = 'tel:' + phone; //设置下载地址
  a.target = "_blank";
  a.dispatchEvent(e); //给指定的元素，执行事件click事件
}
function sleep(miliseconds = 1500) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, miliseconds);
  });
}
function toBase64String(str) {
  return Base64.encodeURI(str);
}
function shareVersion() {
  return moment().format('YYYYMMDDHHmmss');
}
function downloadBrowser({ name, href }) {
  var a = document.createElement("a"),
    e = document.createEvent("MouseEvents");
  e.initEvent("click", false, false);
  a.href = href;
  a.target = "_blank";
  if (name) a.download = encodeURIComponent(name);
  a.dispatchEvent(e);
}
function randomPassword() {
  return (Math.random() + "").substr(2, 6);
}
function alert(text) {
  return Dialog.alert({ title: "提示", message: text });
}
function isDecimal(n) {
  try {
    new Decimal(n);
    return true;
  } catch (e) {

  }
  return false;
}
function d(n = 0) {
  return new Decimal(n);
}
function ds(n, l = 0) {
  if (!n) return "";
  return d(n).toFixed(l);
}
function dsp(n, l = 0) {
  if (!n) return "";
  let v = d(n).times(100).toFixed(l);
  if (v === 'NaN') return '-';
  return v + '%';
}
function textToColor(str) {
  if (!str || str.length === 0)
    return false;
  for (var i = 0, hash = 0; i < str.length; hash = str.charCodeAt(i++) + ((hash << 5) - hash));
  for (var i = 0, color = '#'; i < 3; color += ('00' + ((hash >> i++ * 2) & 0xFF).toString(16)).slice(-2));
  return color;
}
function setStoreAdmin({ userid, username, name, role }) {
  localStorage.setItem("lidi.userid", userid);
  localStorage.setItem("lidi.username", username);
  localStorage.setItem("lidi.name", name);
  localStorage.setItem("lidi.role", role);
  store.dispatch("SetUserid", userid);
  store.dispatch("SetUsername", username);
  store.dispatch("SetName", name);
  store.dispatch("SetRole", role);
}
function formatMoney(number, places, symbol, thousand, decimal) {
  number = Number(number) || 0;
  places = !isNaN(places = Math.abs(places)) ? places : 0;
  symbol = symbol !== undefined ? symbol : "￥";
  thousand = thousand || ",";
  decimal = decimal || ".";
  var negative = number < 0 ? "-" : "";
  var i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
    j = (j = i.length) > 3 ? j % 3 : 0;
  return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
}
/**
 * 获取文件
 * @param url
 * @returns {Promise<any>}
 */
function getBlob(url) {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      }
    }
    xhr.send();
  })
}
function getFile(url) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url,
      responseType: 'arraybuffer'
    }).then(data => {
      resolve(data.data);
    }).catch(error => {
      reject(error.toString());
    })
  })
}
function saveAs(blob, name) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, name);
    Toast.success("下载完成");
  } else {
    const link = document.createElement('a');
    const body = document.querySelector('body');
    link.href = window.URL.createObjectURL(blob); // 创建对象url
    link.download = name;
    // fix Firefox
    link.style.display = 'none';
    body.appendChild(link);
    link.click();
    body.removeChild(link);
    window.URL.revokeObjectURL(link.href); // 通过调用 URL.createObjectURL() 创建的 URL 对象
    Toast.success("下载完成");
  }
}
function download(url, name = '') {
  Toast.loading({ message: "正在下载" });
  getBlob(url).then((blob) => {
    saveAs(blob, name);
  }).catch(() => {
    Toast.fail("下载失败");
  });
}
/**
 * 批量打包zip包下载
 * @param files Array [{url: 下载文件的路径, name: 下载文件名称}]
 * @param zipname zip文件名
 */
function downloadZip(files, zipname) {
  zipname = zipname || "打包下载";
  if (files.length === 0) return;
  if (files.length === 1) return download(files[0].url, files[0].name);
  Toast.loading({ message: "正在下载" });
  const zip = new JSZip();
  const cache = {};
  const promises = [];
  files.forEach((item) => {
    const promise = getBlob(item.url).then((data) => { // 下载文件, 并存成ArrayBuffer对象
      zip.file(item.name, data, { binary: true }) // 逐个添加文件
      cache[item.name] = data
    })
    promises.push(promise);
  });

  Promise.all(promises).then(() => {
    zip.generateAsync({ type: 'blob' }).then((content) => { // 生成二进制流
      FileSaver.saveAs(content, `${zipname}.zip`); // 利用file-saver保存文件
      Toast.success("下载完成");
    });
  });
}
function numtocn(num) {
  return nzhcn.toMoney(num).replace('人民币', '');
}
export default {
  sliceFile,
  makeUUID,
  dateTime,
  fromNow,
  callPhone,
  toBase64String,
  downloadBrowser,
  sleep,
  randomPassword,
  shareVersion,
  alert,
  isDecimal,
  d,
  ds,
  dsp,
  moment,
  textToColor,
  setStoreAdmin,
  formatMoney,
  downloadZip,
  numtocn,
};
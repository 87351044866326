<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" md="2" sm="2">
            <v-text-field
                    v-model="x_driver_name"
                    outlined
                    dense
                    label="司机名称"
                    clearable
            />
          </v-col>
          <v-col cols="12" md="2" sm="2">
            <v-text-field
                    v-model="x_phone"
                    item-text="label"
                    item-value="value"
                    dense
                    outlined
                    label="司机手机号"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-btn color="cyan"  @click="getdata"  style="width: 100px;color:#fff">查询</v-btn>
            <v-btn color="primary"  style="margin-left:10px;" @click="showadd">
              <v-icon dark>mdi-plus</v-icon>
              新增司机
            </v-btn>
            <v-btn color="success" style="margin-left:10px;" @click="batch">
              <v-icon dark>mdi-arrow-up-circle-outline</v-icon>
              导入司机
            </v-btn>
          </v-col>
          <v-col cols="12" md="12" sm="12">

          </v-col>
        </v-row>
      </v-card-title>

    </v-card>
    <v-card>
      <v-data-table
              :headers="headers"
              :items="list"
              :items-per-page="limit"
              no-data-text="无数据"
              hide-default-footer
              disable-sort
      >
        <template v-slot:item.x_state="{ item }">
                    <span style="color:red" v-if="item.x_state">
                        禁用
                    </span>
          <span style="color:#01579B" v-else>
                        正常
                    </span>
        </template>
        <template v-slot:item.x_driver_lic_url="{ item }">
          <a v-if="item.x_driver_lic_url" :href="item.x_driver_lic_url" target="_blank">查看大图</a>
        </template>
        <template v-slot:item.action="{ item }">
          <div style="display:flex;justify-content: flex-start">
            <v-btn
                    text
                    small
                    color="success"
                    @click="showedit(item)"
            >
              编辑
            </v-btn>

            <a-popconfirm
                    title="删除司机"
                    ok-text="确定"
                    cancel-text="取消"
                    @confirm="removeaccount(item.x_driver_id)"
            >
              <v-btn
                      text
                      small
                      color="error"
              >
                删除
              </v-btn>
            </a-popconfirm>
            <v-btn
                    text
                    small
                    color="primary"
                    @click="showStatus(item.x_driver_id)"
                    v-if="
                      item.x_state
                    ">
              启用
            </v-btn>
          </div>
        </template>
      </v-data-table>
      <v-pagination
              v-if="length > 1"
              v-model="page"
              :page="page"
              :length="length"
              total-visible="10"
      />
    </v-card>
    <v-dialog v-model="model" persistent max-width="800px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title
                  class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between"
          >
            {{ modeltitle }}
            <v-icon @click="cancelsave">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                          v-model="accountinfo.x_driver_name"
                          :rules="[vrules.required]"
                          placeholder="输入司机名称"
                          
                          persistent-placeholder
                          dense
                          outlined
                  >
                    <template v-slot:label>
                      司机名称
                      <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                <v-text-field
                        v-model="accountinfo.x_phone"
                        :rules="[vrules.required]"
                        placeholder="输入司机手机号"
                        
                        persistent-placeholder
                        dense
                        outlined
                >
                  <template v-slot:label>
                    司机手机号
                    <span class="red--text">*</span>
                  </template>
                </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select
                          v-model="accountinfo.x_team_id"
                          :items="upStreamList"
                          item-text="x_team_name"
                          item-value="x_team_id"
                          dense
                          outlined
                          label="请选择车队"
                          required
                  >
                    <template v-slot:label>
                      所属车队
                    </template>
                  </v-select>
                </v-col>

              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                          v-model="accountinfo.x_employer"
                          placeholder="输入所属单位"
                          
                          persistent-placeholder
                          dense
                          outlined
                  >
                    <template v-slot:label>
                      所属单位
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                          v-model="accountinfo.x_driver_lic"
                          placeholder="驾驶证号"
                          :rules="[vrules.required]"
                          
                          persistent-placeholder
                          dense
                          outlined
                  >
                    <template v-slot:label>
                      驾驶证号
                      <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                          v-model="accountinfo.x_driving_type"
                          :rules="[vrules.required]"
                          placeholder="驾驶证类型"
                          
                          persistent-placeholder
                          dense
                          outlined
                  >
                    <template v-slot:label>
                      驾驶证类型
                      <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
<!--                <v-col cols="4">-->
<!--                  <v-text-field-->
<!--                          v-model="accountinfo.x_contacts"-->
<!--                          placeholder="输入所属单位联系人"-->
<!--                          -->
<!--                          persistent-placeholder-->
<!--                          dense-->
<!--                          outlined-->
<!--                  >-->
<!--                    <template v-slot:label>-->
<!--                      所属单位联系人-->
<!--                    </template>-->
<!--                  </v-text-field>-->
<!--                </v-col>-->
<!--                <v-col cols="4">-->
<!--                  <v-text-field-->
<!--                          v-model="accountinfo.x_contacts_phone"-->
<!--                          placeholder="单位联系人手机号"-->
<!--                          -->
<!--                          persistent-placeholder-->
<!--                          dense-->
<!--                          outlined-->
<!--                  >-->
<!--                    <template v-slot:label>-->
<!--                      单位联系人手机号-->
<!--                    </template>-->
<!--                  </v-text-field>-->
<!--                </v-col>-->
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-file-input
                          v-model="file"
                          @change="fileFun"
                          accept="image/*"
                          label="驾驶证照片"
                          dense
                          outlined
                  >
                    <template v-slot:label>
                      驾驶证照片
                      <span class="red--text">*</span>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="4">
                  <img style="width: 100px"  :src="accountinfo.x_driver_lic_url" alt="">
                </v-col>
              </v-row>
              <v-row>

                <v-col cols="6">
                  <v-menu
                          ref="menu1"
                          v-model="menu1"
                          :close-on-content-click="false"
                          :return-value.sync="accountinfo.x_start_date"
                          transition="scale-transition"
                          min-width="auto"
                          offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                              v-model="accountinfo.x_start_date"
                              label="驾驶证有效期开始时间"
                              placeholder="驾驶证有效期开始时间"
                              append-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              dense
                              outlined
                              clearable
                      >
                        <template v-slot:label> 驾驶证有效期开始时间 </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                            v-model="accountinfo.x_start_date"
                            no-title
                            value="YYYY-mm-dd"
                            scrollable
                            locale="zh-cn"
                            @input="
                    $refs.menu1.save(accountinfo.x_start_date);
                    menu1 = false;
                  "
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                          ref="menu2"
                          v-model="menu2"
                          :close-on-content-click="false"
                          :return-value.sync="accountinfo.x_end_date"
                          transition="scale-transition"
                          min-width="auto"
                          offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                              v-model="accountinfo.x_end_date"
                              label="驾驶证到期时间"
                              placeholder="驾驶证到期时间"
                              append-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              dense
                              outlined
                              clearable
                      >
                        <template v-slot:label> 驾驶证到期时间(不选为长期) </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                            v-model="accountinfo.x_end_date"
                            no-title
                            value="YYYY-mm-dd"
                            scrollable
                            locale="zh-cn"
                            @input="
                    $refs.menu2.save(accountinfo.x_end_date);
                    menu2 = false;
                  "
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="saveaccount">确定</v-btn>
            <v-btn @click="cancelsave">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="model2" persistent max-width="400px">
      <v-form ref="form2" v-model="valid2" lazy-validation>
        <v-card>
          <v-card-title
                  class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between"
          >上传文件
            <v-icon @click="model2=false">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-file-input
                          v-model="file2"
                          accept=".xls,.xlsx"
                          label="文件"
                          dense
                          outlined
                  >
                    <template v-slot:label>
                      文件
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
              <v-row style="padding-left: 20px;">
                <a href="https://dafangsupplychain.oss-cn-zhangjiakou.aliyuncs.com/public/admin_lidi/%E5%8F%B8%E6%9C%BA%E4%BF%A1%E6%81%AF%E6%A8%A1%E6%9D%BF.xlsx">下载司机模板</a>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="saveaccount1">确定</v-btn>
            <v-btn @click="model2=false">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
  import _ from "underscore";
  import {mapState} from "vuex";

  export default {
    name: "DriverList",
    data() {
      return {
        upStreamList: [],
        valid: true,
        headers: [
          {text: "司机编号", value: "x_driver_id", width: 80, align: "center"},
          {text: "司机名称", value: "x_driver_name", width: 150, align: "center"},
          {text: "司机手机号", value: "x_phone", width: 120, align: "center"},
          {text: "驾驶证URL", value: "x_driver_lic_url", width: 120, align: "center"},
          {text: "驾驶证号", value: "x_driver_lic", width: 120, align: "center"},
          {text: "驾驶证类型", value: "x_driving_type", width: 120, align: "center"},
          {text: "驾驶证有效期开始时间", value: "x_start_date", width: 120, align: "center"},
          {text: "驾驶证有效期截止时间", value: "x_end_date", width: 100, align: "center"},
          {text: "状态", value: "x_state", width: 100, align: "center"},
          {text: "所属单位", value: "x_unit", width: 100, align: "center"},
          {text: "车队名称", value: "x_team_name", width: 100, align: "center"},
          {text: "车队联系人", value: "x_team_contact", width: 100, align: "center"},
          {text: "车队联系人手机", value: "x_team_phone", width: 100, align: "center"},
          {text: "创建人", value: "create_by", width: 100, align: "center"},
          {text: "创建时间", value: "create_time", width: 200, align: "center"},
          {text: "操作", value: "action", width: 180, align: "center"},
        ],
        x_driver_name: '',
        x_phone: '',
        page: 1,
        limit: 15,
        list: [],
        length: 0,
        model: false,
        modeltitle: "",
        vrules: {
          required: (value) => !!value || "不能为空",
          username: (value) =>
                  /[a-zA-Z0-9]{5,12}/.test(value) || "司机只能包含字母数字，长度5-12位",
          password: (value) =>
                  (value.length >= 6 && value.length <= 20) || "密码长度6-20位",
        },
        accountinfo: {
          x_driver_id:"",
          x_team_id: '',
          x_driver_name: "",
          x_phone: "",
          x_employer: "",
          // x_contacts: "",
          // x_contacts_phone: "",
          x_driver_lic_url: "",
          x_driver_lic: "",
          x_driving_type: "",
          x_start_date: "",
          x_end_date: "",
        },
        file: null,
        menu1: false,
        menu2: false,
        valid2: true,
        model2: false,
        file2:null,
        fileUrl:''
      };
    },
    computed: {
      ...mapState(["role", "userid", "roles", "roles2", "rolecolors", "rolenames"]),
    },
    components: {},
    watch: {
      page() {
        this.getdata();
      },
    },
    created() {
      this.$api
              .doAPI({
                Action: "ldadmin_teamlist",
                Data: {
                  page: 1,
                  limit:100,
                },
              })
              .then((res) => {
                this.$toast.clear();
                if (res.code === 0) {
                  this.upStreamList = res.data.list;
                } else {
                  this.$utils.alert(res.msg);
                }
              })
    },
    activated() {
      this.page = 1;
      this.list = []
      this.length = 0;
      this.getdata();
    },
    methods: {
      batch(){
        this.model2=true
        this.fileUrl=null
        this.file2=null
      },
      fileFun(){
        let formData = new FormData();
        formData.append('file', this.file);
        formData.append('Action', 'ldadmin_upload');
        this.$api.post('/api/upload/image',formData).then(res=>{
          this.accountinfo.x_driver_lic_url=res.data
        })
      },
      saveaccount1() {
        let formData = new FormData();
        formData.append('file', this.file2);
        formData.append('Action', 'ldadmin_driverimport');
        formData.append('filetype', 'excel');
        this.$api.post('/api/driverimport',formData).then((res) => {
                  this.$toast.clear();
                  if (res.code === 0) {
                    this.model2 = false;
                    this.getdata();
                  } else {
                    this.$utils.alert(res.msg);
                  }
                })
                .catch((err) => {
                  this.$toast.clear();
                  console.error(err);
                });
      },
      getdata() {
        this.$toast.loading({message: ""});
        this.$api
                .doAPI({
                  Action: "ldadmin_driverlist",
                  Data: {
                    page: this.page,
                    limit: this.limit,
                    x_driver_name: this.x_driver_name,
                    x_phone: this.x_phone,
                  },
                })
                .then((res) => {
                  this.$toast.clear();
                  if (res.code === 0) {
                    this.list = res.data.list;
                    this.length = Number((res.data.count/this.limit).toFixed(0))+1;
                    if (this.length > 0 && this.page > this.length) this.page = 1;
                  } else {
                    this.$utils.alert(res.msg);
                  }
                })
                .catch((err) => {
                  this.$toast.clear();
                  console.error(err);
                });
      },
      showadd() {
        this.accountinfo = {
          x_driver_id:'',
          x_team_id: '',
          x_driver_name: "",
          x_phone: "",
          x_employer: "",
          // x_contacts: "",
          // x_contacts_phone: "",
          x_driver_lic_url: "",
          x_driver_lic: "",
          x_driving_type: "",
          x_start_date: "",
          x_end_date: "",
        };
        this.modeltitle = "新增司机";
        this.model = true;
      },
      showedit(item) {
        this.accountinfo = _.pick(item, _.keys(this.accountinfo));
        this.modeltitle = "编辑司机";
        this.model = true;
      },
      saveaccount() {
        if (!this.$refs.form.validate()) return;
        if (this.accountinfo.x_driver_id) {
          this.$toast.loading({message: ""});
          this.$api
                  .doAPI({
                    Action: "ldadmin_driveredit",
                    Data: {...this.accountinfo},
                  })
                  .then((res) => {
                    this.$toast.clear();
                    if (res.code === 0) {
                      this.model = false;
                      this.getdata();
                    } else {
                      this.$utils.alert(res.msg);
                    }
                  })
                  .catch((err) => {
                    this.$toast.clear();
                    console.error(err);
                  });
        } else {
          this.$toast.loading({message: ""});
          this.$api
                  .doAPI({
                    Action: "ldadmin_driveradd",
                    Data: {...this.accountinfo},
                  })
                  .then((res) => {
                    this.$toast.clear();
                    if (res.code === 0) {
                      this.model = false;
                      this.getdata();
                    } else {
                      this.$utils.alert(res.msg);
                    }
                  })
                  .catch((err) => {
                    this.$toast.clear();
                    console.error(err);
                  });
        }

      },
      cancelsave() {
        this.$refs.form.resetValidation();
        this.model = false;
      },
      showStatus(id) {
        this.$toast.loading({message: ""});
        this.$api
                .doAPI({
                  Action: "ldadmin_driveren",
                  Data: {x_driver_id: id},
                })
                .then((res) => {
                  this.$toast.clear();
                  if (res.code === 0) {
                    this.getdata();
                  } else {
                    this.$utils.alert(res.msg);
                  }
                })
                .catch((err) => {
                  this.$toast.clear();
                  console.error(err);
                });
      },
      removeaccount(id) {
        this.$toast.loading({message: ""});
        this.$api
                .doAPI({
                  Action: "ldadmin_driverdel",
                  Data: {x_driver_id: id},
                })
                .then((res) => {
                  this.$toast.clear();
                  if (res.code === 0) {
                    this.getdata();
                  } else {
                    this.$utils.alert(res.msg);
                  }
                })
                .catch((err) => {
                  this.$toast.clear();
                  console.error(err);
                });
      },
    },
  };
</script>
<style lang="less" scoped>
  /deep/ .row {
    margin-bottom: 0 !important;
  }

  /deep/ .col-12 {
    padding-bottom: 0 !important;
  }

  /deep/ .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }

  /deep/ .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 0 4px;
  }
</style>

import Vue from "vue"
import Vuex from "vuex"
import mutations from "./mutations"
import actions from "./actions"
import _ from "underscore"
import utils from "../utils";

Vue.use(Vuex)

const state = {
  initmenus: [
    {
      icon: "mdi-view-dashboard",
      name: "利滴大数据",
      active: false,
      roles: [],
      path: "/workbench",
    },
    {
      icon: "mdi-database",
      name: "邮路管理",
      active: false,
      roles: ["root", "admin"],
      children: [
        {
          name: "邮路列表",
          active: false,
          path: "/post/list",
        },
        // {
        //   name: "添加邮路",
        //   active: false,
        //   path: "/post/add",
        // }
      ],
    },
    {
      icon: "mdi-clipboard-text-outline",
      name: "运单管理",
      active: false,
      roles: ["root", "admin"],
      children: [
        {
          name: "运单列表",
          active: false,
          path: "/orders/list",
        },
        {
          name: "创建运单",
          active: false,
          path: "/orders/create",
        },
        // {
        //   name: "运单报表",
        //   active: false,
        //   path: "/orders/waybill",
        // },
      ],
    },
    {
      icon: "mdi-account-tie",
      name: "司机管理",
      active: false,
      roles: ["root", "admin"],
      children: [
        {
          name: "司机列表",
          active: false,
          path: "/driver/list",
        },
        {
          name: "车队列表",
          active: false,
          path: "/driver/motorcade",
        }
      ],
    },
    {
      icon: "mdi-account-box-multiple",
      name: "车辆管理",
      active: false,
      roles: ["root", "admin"],
      children: [
        {
          name: "车辆列表",
          active: false,
          path: "/vehicle/list",
        }
      ],
    },
    {
      icon: "mdi-home-city",
      name: "电子合同管理",
      active: false,
      roles: [],
      children: [
        {
          name: "合同列表",
          active: false,
          path: '/contract/list'
        },
        {
          name: "添加合同",
          active: false,
          path: "/contract/add",
        }
      ],
    },
    {
      icon: "mdi-cog",
      name: "系统管理",
      active: false,
      roles: ["root", "admin"],
      children: [
        {
          icon: "mdi-account-group",
          name: "账户管理",
          active: false,
          roles: ["root", "admin"],
          path: "/system/account",
        },
        {
          icon: "mdi-account",
          name: "角色管理",
          active: false,
          roles: ["root", "admin"],
          path: "/system/roles",
        },
        {
          icon: "mdi-math-log",
          name: "系统日志",
          active: false,
          roles: ["root", "admin"],
          path: "/system/log",
        },
      ],
    },
    {
      icon: "mdi-account-cog",
      name: "个人中心",
      active: false,
      roles: [],
      children: [
        {
          icon: "mdi-account",
          name: "账户设置",
          active: false,
          roles: [],
          path: "/user/setting",
        },
      ],
    },
  ],
  rolecolors: {
    "root": "#5470c6",
    "admin": "#91cc75",
    // "caiwu": "#fac858",
    // "salesman": "#ee6666",
    // "kefu": "#3ba272",
    "staff": "#fc8452",
    // "": "#73c0de",
    // "": "#9a60b4",
    // "": "#ea7ccc",
    // "": "primary",
  },
  rolenames: {
    root: "超级管理员",
    admin: "管理员",
    // caiwu: "财务",
    // salesman: "销售",
    // kefu: "客服",
    staff: "员工",
  },
  roles: [
    // { text: "财务", value: "caiwu" },
    // { text: "销售", value: "salesman" },
    // { text: "客服", value: "kefu" },
    { text: "员工", value: "staff" },
  ],
  roles2: [
    { text: "管理员", value: "admin" },
    // { text: "财务", value: "caiwu" },
    // { text: "销售", value: "salesman" },
    // { text: "客服", value: "kefu" },
    { text: "员工", value: "staff" },
  ],
  allroles: [
    { text: "超级管理员", value: "root" },
    { text: "管理员", value: "admin" },
    // { text: "财务", value: "caiwu" },
    // { text: "销售", value: "salesman" },
    // { text: "客服", value: "kefu" },
    { text: "员工", value: "staff" },
  ],
  avatar: "",
  userid: "",
  username: "",
  name: "",
  role: "",
  navTitle: "",
  navLogo: "",
  navTabs: [],
  navTabIndex: -1,
}
function checkRoles(roles, role) {
  return !_.isArray(roles) || roles.length === 0 || roles.indexOf(role) > -1;
}
const getters = {
  rolename: (state) => () => {
    return state.rolenames[state.role];
  },
  getmenus: (state) => (path) => {
    let menus = [];
    for (let i = 0; i < state.initmenus.length; i++) {
      let menu = { ...state.initmenus[i] };
      if (checkRoles(menu.roles, state.role) === false) continue;
      if (menu.username && menu.username !== state.username) continue;
      if (menu.children) {
        menu.active = !!_.findWhere(menu.children, { path });
        let children = [];
        for (let j = 0; j < menu.children.length; j++) {
          let cmenu = menu.children[j];
          if (checkRoles(cmenu.roles, state.role) === false) continue;
          if (cmenu.username && cmenu.username !== state.username) continue;
          cmenu.active = cmenu.path === path;
          children.push({ ...cmenu });
        }
        menu.children = children;
        if (menu.children.length > 0) menus.push({ ...menu })
      } else {
        menu.active = menu.path === path;
        menus.push({ ...menu });
      }
    }
    return menus;
  },
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
});

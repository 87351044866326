var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"Contractlist"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2","sm":"2"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"司机姓名","clearable":""},model:{value:(_vm.driver_name),callback:function ($$v) {_vm.driver_name=$$v},expression:"driver_name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"2"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"合同编号","clearable":""},model:{value:(_vm.contract_no),callback:function ($$v) {_vm.contract_no=$$v},expression:"contract_no"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"4","sm":"4"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"出发地","clearable":""},model:{value:(_vm.start_place),callback:function ($$v) {_vm.start_place=$$v},expression:"start_place"}}),_vm._v(" --> "),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"目的地","clearable":""},model:{value:(_vm.end_place),callback:function ($$v) {_vm.end_place=$$v},expression:"end_place"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"2"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"return-value":_vm.sign_day,"transition":"scale-transition","min-width":"auto","offset-y":""},on:{"update:returnValue":function($event){_vm.sign_day=$event},"update:return-value":function($event){_vm.sign_day=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"签署日期","placeholder":"签署日期","append-icon":"mdi-calendar","readonly":"","dense":"","outlined":"","clearable":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" 签署日期 ")]},proxy:true}],null,true),model:{value:(_vm.sign_day),callback:function ($$v) {_vm.sign_day=$$v},expression:"sign_day"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","locale":"zh-cn"},on:{"input":function($event){_vm.$refs.menu1.save(_vm.sign_day);
                  _vm.menu1 = false;}},model:{value:(_vm.sign_day),callback:function ($$v) {_vm.sign_day=$$v},expression:"sign_day"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"2"}},[_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"color":"primary"},on:{"click":_vm.getdata}},[_vm._v("查询")])],1)],1)],1),_c('div',{staticClass:"op"},[_c('v-btn',{attrs:{"small":"","color":"primary","disabled":_vm.selected.length === 0},on:{"click":_vm.downloadcontracts}},[_vm._v(" 下载电子合同 ")]),_c('v-btn',{attrs:{"small":"","text":"","color":"primary","outlined":""},on:{"click":_vm.getdata}},[_vm._v("刷新")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.list,"items-per-page":_vm.limit,"no-data-text":"无数据","hide-default-footer":"","disable-sort":"","item-key":"_id","show-select":""},scopedSlots:_vm._u([{key:"item.contract_duration",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.start_day)+"至"+_vm._s(item.end_day)+" ")]}},{key:"item.line",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.start_place)+"-"+_vm._s(item.end_place)+" ")]}},{key:"item.contract_url",fn:function(ref){
                  var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.showcontract(item._id)}}},[_vm._v(" 查看 ")])]}},{key:"item.accountInfo",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(item.bankcard_no))]),_c('span',[_vm._v(_vm._s(item.bankcard_host))]),_c('span',[_vm._v(_vm._s(item.bank_name))])])]}},{key:"item.signstate",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.signature ? "已签字" : "未签字")+" ")]}},{key:"item.state",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.pdfurl ? "电子合同已生成" : "电子合同未生成")+" ")]}},{key:"item.create_time",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.create_time,'YYYY-MM-DD HH:mm:ss'))+" ")]}},{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [(!item.signature)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.editItem(item._id)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),_c('v-icon',{attrs:{"small":"","color":"orange"},on:{"click":function($event){return _vm.deleteItem(item._id)}}},[_vm._v("mdi-delete")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.length > 1)?_c('v-pagination',{attrs:{"page":_vm.page,"length":_vm.length,"total-visible":"10"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
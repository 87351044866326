<template>
    <v-container fluid>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col cols="12" md="2" sm="2">
                        <v-text-field
                                v-model="x_oil_name"
                                outlined
                                dense
                                label="邮路名称"
                                clearable
                        />
                    </v-col>
                    <v-col cols="12" md="2" sm="2">
                        <v-text-field
                                v-model="x_belong_bureau"
                                item-text="label"
                                item-value="value"
                                dense
                                outlined
                                label="所属局"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" sm="2" class="d-flex">
                        <v-select
                                v-model="x_province"
                                :items="addressList"
                                item-text="name"
                                item-value="name"
                                dense
                                outlined
                                clearable
                                label="请选择省份"
                                required
                        >
                            <template v-slot:label>
                                省份
                            </template>
                        </v-select>
                    </v-col>
                        <v-col cols="12" md="4" sm="4" class="d-flex">
                        <v-text-field style="padding-right: 20px;" v-model="x_proven" outlined dense label="始发地"
                                      clearable/>
                        <v-text-field v-model="x_destina" outlined dense label="目的地" clearable/>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-title>
                <v-row style="padding:10px 0;">
                    <v-col cols="12" md="12" sm="12">
                        <v-btn  @click="getdata" color="cyan" style="width: 100px;color:#fff">查询</v-btn>
                        <v-btn color="primary" style="margin-left:10px;" @click="showadd">
                            <v-icon dark>mdi-plus</v-icon>
                            新增邮路
                        </v-btn>
                        <v-btn color="success"  @click="batch" style="margin-left:10px;">
                            <v-icon dark>mdi-arrow-up-circle-outline</v-icon>
                            导入邮路
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
        </v-card>
        <v-card>
            <v-data-table
                    :headers="headers"
                    :items="list"
                    :items-per-page="limit"
                    no-data-text="无数据"
                    hide-default-footer
                    disable-sort
            >
                <template v-slot:item.x_oil_type_name="{ item }">
                    <span style="color:red" v-if="item.x_oil_type==1">
                        邮政中心局
                    </span>
                    <span style="color:#01579B" v-else>
                        地方分局
                    </span>
                </template>
                <template v-slot:item.x_state="{ item }">
                    <span style="color:red" v-if="item.x_state">
                        禁用
                    </span>
                    <span style="color:#01579B" v-else>
                        正常
                    </span>
                </template>
                <template v-slot:item.x_team_name="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }" v-if="item.x_team.length">
                            <a href="javascript:;"   v-bind="attrs"
                                     v-on="on">
                                   车队详情
                            </a>
<!--                            <v-btn-->
<!--                                    color="primary"-->
<!--                                    dark-->
<!--                                    v-bind="attrs"-->
<!--                                    v-on="on"-->
<!--                            >-->
<!--                                车队详情-->
<!--                            </v-btn>-->
                        </template>
                        <v-data-table
                                :headers="header"
                                :items="item.x_team"
                                no-data-text="无数据"
                                hide-default-footer
                                disable-sort
                        ></v-data-table>
                    </v-tooltip>
                </template>
<!--                <template v-slot:item.x_team_name="{ item }">-->
<!--                    {{item.x_team.length?item.x_team[0].x_team_name:''}}-->
<!--                </template>-->
<!--                <template v-slot:item.x_team_contact="{ item }">-->
<!--                    {{item.x_team.length?item.x_team[0].x_team_contact:''}}-->
<!--                </template>-->
<!--                <template v-slot:item.x_team_phone="{ item }">-->
<!--                    {{item.x_team.length?item.x_team[0].x_team_phone:''}}-->
<!--                </template>-->
                <template v-slot:item.action="{ item }">
                    <div style="display:flex;justify-content: flex-start">

                        <v-btn
                                text
                                small
                                color="success"
                                @click="showedit(item)"
                        >
                            编辑
                        </v-btn>
                        <a-popconfirm
                                title="删除邮路"
                                ok-text="确定"
                                cancel-text="取消"
                                @confirm="removeaccount(item.x_oil_id)"
                        >
                            <v-btn
                                    text
                                    small
                                    color="error"
                            >
                                删除
                            </v-btn>
                        </a-popconfirm>
                        <v-btn
                                text
                                small
                                color="primary"
                                @click="showStatus(item.x_oil_id)"
                                v-if="
                      item.x_state
                    ">
                            启用
                        </v-btn>

                    </div>
                </template>
            </v-data-table>
            <v-pagination
                    v-if="length > 1"
                    v-model="page"
                    :page="page"
                    :length="length"
                    total-visible="10"
            />
        </v-card>
        <v-dialog v-model="model2" persistent max-width="400px">
            <v-form ref="form2" v-model="valid2" lazy-validation>
                <v-card>
                    <v-card-title
                            class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between"
                    >上传文件
                        <v-icon @click="model2=false">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-file-input
                                            v-model="file"
                                            accept=".xls,.xlsx"
                                            label="文件"
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            文件
                                        </template>
                                    </v-file-input>
                                </v-col>
                            </v-row>
                            <v-row style="padding-left: 20px;">
                                <a href="https://dafangsupplychain.oss-cn-zhangjiakou.aliyuncs.com/public/admin_lidi/%E9%82%AE%E8%B7%AF%E4%BF%A1%E6%81%AF%E6%A8%A1%E6%9D%BF.xlsx">下载邮路模板</a>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="saveaccount1">确定</v-btn>
                        <v-btn @click="model2=false">关闭</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <v-dialog v-model="model" persistent max-width="800px">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                    <v-card-title
                            class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between"
                    >
                        {{ modeltitle }}
                        <v-icon @click="cancelsave">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                            v-model="accountinfo.x_oil_name"
                                            :rules="[vrules.required]"
                                            placeholder="输入邮路名称"
                                            :disabled="accountinfo.id?true:false"
                                            persistent-placeholder
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            邮路名称
                                            <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <v-select
                                            v-model="accountinfo.x_oil_type"
                                            :items="upStreamList"
                                            item-text="label"
                                            item-value="value"
                                            :rules="[v => !!v || '请选择类型']"
                                            dense
                                            outlined
                                            label="请选择类型"
                                            required
                                    >
                                        <template v-slot:label>
                                            邮路类型
                                            <span class="red--text">*</span>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                            v-model="accountinfo.x_belong_bureau"
                                            placeholder="邮路所属局"
                                            :rules="[vrules.required]"
                                            :disabled="accountinfo.id?true:false"
                                            persistent-placeholder
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            邮路所属局
                                            <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                            v-model="accountinfo.x_inspe_time"
                                            placeholder="输入考核时效"
                                            :rules="[vrules.required]"
                                            :disabled="accountinfo.id?true:false"
                                            persistent-placeholder
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            考核时效(h)
                                            <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <v-select
                                            v-model="accountinfo.x_province"
                                            :items="addressList"
                                            item-text="name"
                                            item-value="name"
                                            dense
                                            outlined
                                            label="请选择省份"
                                            required
                                    >
                                        <template v-slot:label>
                                            所属省份
                                            <span class="red--text">*</span>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                            v-model="accountinfo.x_proven"
                                            :rules="[vrules.required]"
                                            placeholder="始发地"
                                            :disabled="accountinfo.id?true:false"
                                            persistent-placeholder
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            始发地
                                            <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                            v-model="accountinfo.x_destina"
                                            :rules="[vrules.required]"
                                            placeholder="目的地"
                                            :disabled="accountinfo.id?true:false"
                                            persistent-placeholder
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            目的地
                                            <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                <v-select
                                        v-model="accountinfo.team_ids"
                                        :items="teamList"
                                        item-text="x_team_name"
                                        item-value="x_team_id"
                                        :menu-props="{ maxHeight: '400' }"
                                        dense
                                        outlined
                                        label="请选择车队"
                                        persistent-hint
                                        multiple
                                        required
                                >
                                    <template v-slot:label>
                                        车队
                                    </template>
                                </v-select>
                            </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="saveaccount">确定</v-btn>
                        <v-btn @click="cancelsave">关闭</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-container>
</template>

<script>
    import _ from "underscore";
    import {mapState} from "vuex";
    import address from "../../address";

    export default {
        name: "PostList",
        data() {
            return {
                header:[
                    {text: "车队名称", value: "x_team_name", width: 150, align: "center"},
                    {text: "车队联系人", value: "x_team_contact", width: 180, align: "center"},
                    {text: "车队联系人手机号", value: "x_team_phone", width: 200, align: "center"},
                ],
                upStreamList: [{
                    label: '邮政中心局',
                    value: 1
                }, {
                    label: '地方分局',
                    value: 2
                },],
                valid: true,
                valid2: true,
                headers: [
                    {text: "邮路编号", value: "x_oil_id", width: 80, align: "center"},
                    {text: "邮路名称", value: "x_oil_name", width: 150, align: "center"},
                    {text: "邮路类型名称", value: "x_oil_type_name", width: 120, align: "center"},
                    {text: "所属局", value: "x_belong_bureau", width: 120, align: "center"},
                    {text: "所属省份", value: "x_province", width: 120, align: "center"},
                    {text: "始发地", value: "x_proven", width: 120, align: "center"},
                    {text: "目的地", value: "x_destina", width: 120, align: "center"},
                    {text: "考核时效(h)", value: "x_inspe_time", width: 100, align: "center"},
                    {text: "状态", value: "x_state", width: 100, align: "center"},
                    {text: "车队名称", value: "x_team_name", width: 150, align: "center"},
                    // {text: "车队联系人", value: "x_team_contact", width: 180, align: "center"},
                    // {text: "车队联系人手机号", value: "x_team_phone", width: 200, align: "center"},
                    {text: "创建人", value: "create_by", width: 100, align: "center"},
                    {text: "创建时间", value: "create_time", width: 200, align: "center"},
                    {text: "修改人", value: "update_by", width: 100, align: "center"},
                    {text: "修改时间", value: "update_time", width: 200, align: "center"},
                    {text: "操作", value: "action", width: 180, align: "center"},
                ],
                x_oil_name: '',
                x_belong_bureau: '',
                x_province: '',
                x_proven: '',
                x_destina: '',
                page: 1,
                limit: 15,
                list: [],
                length: 0,
                model: false,
                modeltitle: "",
                vrules: {
                    required: (value) => !!value || "不能为空",
                    username: (value) =>
                        /[a-zA-Z0-9]{5,12}/.test(value) || "邮路只能包含字母数字，长度5-12位",
                    password: (value) =>
                        (value.length >= 6 && value.length <= 20) || "密码长度6-20位",
                },
                accountinfo: {
                    x_oil_id: '',
                    x_oil_type: "",
                    x_belong_bureau: "",
                    x_oil_name: "",
                    x_province: "",
                    x_proven: "",
                    x_destina: "",
                    x_inspe_time: "",
                    team_ids:[]
                },
                addressList:[],
                teamList:[],
                model2: false,
                file:null,
                fileUrl:''
            };
        },
        computed: {
            ...mapState(["role", "userid", "roles", "roles2", "rolecolors", "rolenames"]),
        },
        components: {},
        watch: {
            page() {
                this.getdata();
            },
        },
        activated() {
            this.page = 1;
            this.list = []
            this.length = 0;
            this.getdata();
        },
        created() {
            this.$api
                .doAPI({
                    Action: "ldadmin_teamlist",
                    Data: { page: 1, limit: 100 },
                })
                .then((res) => {
                    this.$toast.clear();
                    if (res.code === 0) {
                        this.teamList = res.data.list;
                    } else {
                        this.$utils.alert(res.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.clear();
                    console.error(err);
                });
            this.addressList=address()
        },
        methods: {
            batch(){
                this.model2=true
                this.fileUrl=null
                this.file=null
            },
            saveaccount1() {
                let formData = new FormData();
                formData.append('file', this.file);
                formData.append('Action', 'ldadmin_postalrouimport');
                formData.append('filetype', 'excel');
                this.$api
                .post('/api/postalrouimport',formData)
                    .then((res) => {
                        this.$toast.clear();
                        if (res.code === 0) {
                            this.model2 = false;
                            this.getdata();
                        } else {
                            this.$utils.alert(res.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.clear();
                        console.error(err);
                    });
            },
            getdata() {
                this.$toast.loading({message: ""});
                this.$api
                    .doAPI({
                        Action: "ldadmin_oillist",
                        Data: {
                            page: this.page, limit: this.limit, x_oil_name: this.x_oil_name,
                            x_belong_bureau: this.x_belong_bureau,
                            x_province: this.x_province,
                            x_proven: this.x_proven,
                            x_destina: this.x_destina
                        },
                    })
                    .then((res) => {
                        this.$toast.clear();
                        if (res.code === 0) {
                            this.list = res.data.list;
                            this.length = Number((res.data.count/this.limit).toFixed(0))+1;
                            if (this.length > 0 && this.page > this.length) this.page = 1;
                        } else {
                            this.$utils.alert(res.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.clear();
                        console.error(err);
                    });
            },
            showadd() {
                this.accountinfo = {
                    x_oil_id: '',
                    x_oil_type: "",
                    x_belong_bureau: "",
                    x_oil_name: "",
                    x_province: "",
                    x_proven: "",
                    x_destina: "",
                    team_ids:[],
                    x_inspe_time: "",
                };
                this.modeltitle = "新增邮路";
                this.model = true;
                this.valid=false
                this.$refs.form.resetValidation();
            },
            showedit(item) {
                this.accountinfo = _.pick(item, _.keys(this.accountinfo));
                let list=[]
                if(item.x_team){
                    for (let i = 0; i <item.x_team.length ; i++) {
                        list.push(item.x_team[i].x_team_id)
                    }
                }
                this.accountinfo.team_ids=list
                this.modeltitle = "编辑邮路";
                this.model = true;
            },
            saveaccount() {
                if (!this.$refs.form.validate()) return;
                if (this.accountinfo.x_oil_id) {
                    this.$toast.loading({message: ""});
                    this.$api
                        .doAPI({
                            Action: "ldadmin_oiledit",
                            Data: {...this.accountinfo},
                        })
                        .then((res) => {
                            this.$toast.clear();
                            if (res.code === 0) {
                                this.model = false;
                                this.getdata();
                            } else {
                                this.$utils.alert(res.msg);
                            }
                        })
                        .catch((err) => {
                            this.$toast.clear();
                            console.error(err);
                        });
                } else {
                    this.$toast.loading({message: ""});
                    this.$api
                        .doAPI({
                            Action: "ldadmin_oiladd",
                            Data: {...this.accountinfo},
                        })
                        .then((res) => {
                            this.$toast.clear();
                            if (res.code === 0) {
                                this.model = false;
                                this.getdata();
                            } else {
                                this.$utils.alert(res.msg);
                            }
                        })
                        .catch((err) => {
                            this.$toast.clear();
                            console.error(err);
                        });
                }

            },
            cancelsave() {
                this.$refs.form.resetValidation();
                this.model = false;
            },
            showStatus(id) {
                this.$toast.loading({message: ""});
                this.$api
                    .doAPI({
                        Action: "ldadmin_oilen",
                        Data: {x_oil_id: id},
                    })
                    .then((res) => {
                        this.$toast.clear();
                        if (res.code === 0) {
                            this.getdata();
                        } else {
                            this.$utils.alert(res.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.clear();
                        console.error(err);
                    });
            },
            removeaccount(id) {
                this.$toast.loading({message: ""});
                this.$api
                    .doAPI({
                        Action: "ldadmin_oildel",
                        Data: {x_oil_id: id},
                    })
                    .then((res) => {
                        this.$toast.clear();
                        if (res.code === 0) {
                            this.getdata();
                        } else {
                            this.$utils.alert(res.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.clear();
                        console.error(err);
                    });
            },
        },
    };
</script>
<style lang="less" scoped>
    /deep/ .row {
        margin-bottom: 0 !important;
    }

    /deep/ .col-12 {
        padding-bottom: 0 !important;
    }

    /deep/ .v-input--selection-controls {
        margin-top: 0;
        padding-top: 0;
    }

    /deep/ .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        padding: 0 4px;
    }
</style>

<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="2" sm="2">
                <v-text-field
                        v-model="x_team_name"
                        outlined
                        dense
                        label="车队名称"
                        clearable
                />
            </v-col>
            <v-col cols="12" md="2" sm="2">
                <v-text-field
                        v-model="x_team_contact"
                        outlined
                        dense
                        label="	车队联系人"
                />
            </v-col>
        </v-row>
        <div style="display: flex">
            <v-btn  @click="getdata" color="cyan" style="width: 100px;color:#fff;margin-right: 10px">查询</v-btn>
            <v-btn color="primary" class="mb-2" @click="showadd">
                <v-icon dark>mdi-plus</v-icon>新增车队
            </v-btn>
            <v-btn color="success" @click="batch" style="margin-left:10px;">
                <v-icon dark>mdi-arrow-up-circle-outline</v-icon>
                导入车队
            </v-btn>
        </div>

        <v-card>
            <v-data-table
                    :headers="headers"
                    :items="list"
                    :items-per-page="limit"
                    no-data-text="无数据"
                    hide-default-footer
                    disable-sort
            >
                <template v-slot:item.enable="{ item }">
                    <v-checkbox :input-value="item.enable" hide-details="auto" readonly dense />
                </template>
                <template v-slot:item.username="{ item }">
                    {{ item.username }}
                    <v-chip label small color="green" dark v-if="item.current">当前</v-chip>
                </template>
                <template v-slot:item.role="{ item }">
                    <v-chip label small :color="rolecolors[item.role]" dark>
                        {{ rolenames[item.role] }}
                    </v-chip>
                </template>
<!--                <template v-slot:item.action="{ item }">-->
<!--                    &lt;!&ndash; <v-btn text small color="primary" @click="showverifycode(item._id)">-->
<!--                      校验码-->
<!--                    </v-btn> &ndash;&gt;-->
<!--                    <v-btn-->
<!--                            text-->
<!--                            small-->
<!--                            color="success"-->
<!--                            @click="showedit(item)"-->
<!--                            v-if="-->
<!--              (role === 'root' && item.role !== 'root') ||-->
<!--              (role === 'admin' && item.role !== 'admin')-->
<!--            "-->
<!--                    >-->
<!--                        编辑-->
<!--                    </v-btn>-->
<!--                    <a-popconfirm-->
<!--                            title="删除车队"-->
<!--                            ok-text="确定"-->
<!--                            cancel-text="取消"-->
<!--                            @confirm="removeaccount(item._id)"-->
<!--                    >-->
<!--                        <v-btn-->
<!--                                text-->
<!--                                small-->
<!--                                color="error"-->
<!--                                v-if="-->
<!--                (role === 'root' && item.role !== 'root') ||-->
<!--                (role === 'admin' && item.role !== 'root' && item._id !== userid)-->
<!--              "-->
<!--                        >-->
<!--                            删除-->
<!--                        </v-btn>-->
<!--                    </a-popconfirm>-->
<!--                </template>-->
            </v-data-table>
            <v-pagination
                    v-if="length > 1"
                    v-model="page"
                    :page="page"
                    :length="length"
                    total-visible="10"
            />
        </v-card>
        <v-dialog v-model="model" persistent max-width="400px">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                    <v-card-title
                            class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between"
                    >
                        {{ modeltitle }}
                        <v-icon @click="cancelsave">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                            v-model="accountinfo.x_team_name"
                                            :rules="[vrules.required]"
                                            placeholder="输入车队名称"
                                            :disabled="accountinfo.id?true:false"
                                            persistent-placeholder
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            车队名称
                                            <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                            v-model="accountinfo.x_unit"
                                            placeholder="输入所属单位"
                                            :disabled="accountinfo.id?true:false"
                                            persistent-placeholder
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            所属单位
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                            v-model="accountinfo.x_team_contact"
                                            :rules="[vrules.required]"
                                            placeholder="输入车队联系人"
                                            :disabled="accountinfo.id?true:false"
                                            persistent-placeholder
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            车队联系人
                                            <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                            v-model="accountinfo.x_team_phone"
                                            :rules="[vrules.required]"
                                            placeholder="输入车队联系人电话"
                                            :disabled="accountinfo.id?true:false"
                                            persistent-placeholder
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            车队联系人电话
                                            <span class="red--text">*</span>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary"  @click="saveaccount">确定</v-btn>
                        <v-btn @click="cancelsave">关闭</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <v-dialog v-model="model2" persistent max-width="400px">
            <v-form ref="form2" v-model="valid2" lazy-validation>
                <v-card>
                    <v-card-title
                            class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between"
                    >上传文件
                        <v-icon @click="model2=false">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-file-input
                                            v-model="file"
                                            accept=".xls,.xlsx"
                                            label="文件"
                                            dense
                                            outlined
                                    >
                                        <template v-slot:label>
                                            文件
                                        </template>
                                    </v-file-input>
                                </v-col>
                            </v-row>
                            <v-row style="padding-left: 20px;">
                                <a href="https://dafangsupplychain.oss-cn-zhangjiakou.aliyuncs.com/public/admin_lidi/%E8%BD%A6%E9%98%9F%E4%BF%A1%E6%81%AF%E6%A8%A1%E6%9D%BF.xlsx">下载车队模板</a>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="saveaccount1">确定</v-btn>
                        <v-btn @click="model2=false">关闭</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-container>
</template>

<script>
    import _ from "underscore";
    import { mapState } from "vuex";
    export default {
        name: "DriverMotorcade",
        data() {
            return {
                valid: true,
                headers: [
                    { text: "车队ID", value: "x_team_id", align: "center"  },
                    { text: "车队名称", value: "x_team_name" , align: "center" },
                    { text: "所属公司", value: "x_unit", align: "center"  },
                    { text: "车队联系人", value: "x_team_contact", align: "center" },
                    { text: "车队联系人手机号   ", value: "x_team_phone", align: "center" },
                    { text: "创建人", value: "create_by", align: "center" },
                    { text: "创建时间", value: "create_time", align: "center" },
                    // { text: "操作", value: "action", width: 180 },
                ],
                page: 1,
                limit: 15,
                list: [],
                length: 0,
                model: false,
                modeltitle: "",
                vrules: {
                    required: (value) => !!value || "不能为空",
                    username: (value) =>
                        /[a-zA-Z0-9]{5,12}/.test(value) || "车队只能包含字母数字，长度5-12位",
                    password: (value) =>
                        (value.length >= 6 && value.length <= 20) || "密码长度6-20位",
                },
                accountinfo: {
                    x_unit: "",
                    x_team_name: "",
                    x_team_contact: "",
                    x_team_phone: ""
                },
                valid2:false,
                model2: false,
                file:null,
                fileUrl:'',
                x_team_name:'',
                x_team_contact:'',
            };
        },
        computed: {
            ...mapState(["role", "userid", "roles", "roles2", "rolecolors", "rolenames"]),
        },
        components: {},
        watch: {
            page() {
                this.getdata();
            },
        },
        activated() {
            this.page = 1;
            this.length = 0;
            this.getdata();
        },
        methods: {
            batch(){
                this.model2=true
                this.fileUrl=null
                this.file=null
            },
            saveaccount1() {
                let formData = new FormData();
                formData.append('file', this.file);
                formData.append('Action', 'ldadmin_teamimport');
                formData.append('filetype', 'excel');
                this.$api
                    .post('/api/teamimport',formData)
                    .then((res) => {
                        this.$toast.clear();
                        if (res.code === 0) {
                            this.model2 = false;
                            this.getdata();
                        } else {
                            this.$utils.alert(res.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.clear();
                        console.error(err);
                    });
            },
            getdata() {
                this.$toast.loading({ message: "" });
                this.$api
                    .doAPI({
                        Action: "ldadmin_teamlist",
                        Data: { page: this.page, limit: this.limit ,x_team_contact:this.x_team_contact,x_team_name:this.x_team_name},
                    })
                    .then((res) => {
                        this.$toast.clear();
                        if (res.code === 0) {
                            this.list = res.data.list;
                            this.length = Number((res.data.count/this.limit).toFixed(0))+1;
                            if (this.length > 0 && this.page > this.length) this.page = 1;
                        } else {
                            this.$utils.alert(res.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.clear();
                        console.error(err);
                    });
            },
            showadd() {
                this.accountinfo = {
                    x_unit: "",
                    x_team_name: "",
                    x_team_contact: "",
                    x_team_phone: ""
                };
                this.modeltitle = "新增车队";
                this.model = true;
            },
            showedit(item) {
                this.accountinfo = _.pick(item, _.keys(this.accountinfo));
                this.modeltitle = "编辑车队";
                this.model = true;
            },
            saveaccount() {
                if (!this.$refs.form.validate()) return;
                this.$toast.loading({ message: "" });
                this.$api
                    .doAPI({
                        Action: "ldadmin_teamadd",
                        Data: { ...this.accountinfo },
                    })
                    .then((res) => {
                        this.$toast.clear();
                        if (res.code === 0) {
                            this.model = false;
                            this.getdata();
                        } else {
                            this.$utils.alert(res.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.clear();
                        console.error(err);
                    });
            },
            cancelsave() {
                this.$refs.form.resetValidation();
                this.model = false;
            },
            removeaccount(id) {
                this.$toast.loading({ message: "" });
                this.$api
                    .doAPI({
                        Action: "ldadmin_removeaccount",
                        Data: { id },
                    })
                    .then((res) => {
                        this.$toast.clear();
                        if (res.code === 0) {
                            this.getdata();
                        } else {
                            this.$utils.alert(res.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.clear();
                        console.error(err);
                    });
            },
        },
    };
</script>
<style lang="less" scoped>
    /deep/ .row {
        margin-bottom: 0 !important;
    }
    /deep/ .col-12 {
        padding-bottom: 0 !important;
    }
    /deep/ .v-input--selection-controls {
        margin-top: 0;
        padding-top: 0;
    }
    /deep/ .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        padding: 0 4px;
    }
</style>

<template>
  <v-app id="OrdersCreate">
    <v-container fluid>
<!--      <v-row>-->
<!--        <v-col cols="12" md="6" sm="6">-->
<!--          <v-card>-->
<!--            <v-card-title>-->
<!--              <div class="header">基本信息</div>-->
<!--            </v-card-title>-->
<!--            <div class="form basic">-->
<!--              <v-row>-->
<!--                <v-col cols="12" md="8" sm="6">-->
<!--                  <v-autocomplete-->
<!--                    v-model="value"-->
<!--                    :items="sltcustomers"-->
<!--                    dense-->
<!--                    outlined-->
<!--                    label="选择客户"-->
<!--                  ></v-autocomplete>-->
<!--                </v-col>-->
<!--                <v-col cols="12" md="4" sm="6">-->
<!--                  <v-autocomplete-->
<!--                    v-model="value"-->
<!--                    :items="sltcustomers"-->
<!--                    dense-->
<!--                    outlined-->
<!--                    label="运输渠道"-->
<!--                  ></v-autocomplete>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--              <v-row>-->
<!--                <v-col cols="12" md="4" sm="6">-->
<!--                  <v-autocomplete-->
<!--                    v-model="value"-->
<!--                    :items="sltcustomers"-->
<!--                    dense-->
<!--                    outlined-->
<!--                    label="目的地"-->
<!--                  ></v-autocomplete>-->
<!--                </v-col>-->
<!--                <v-col cols="12" md="4" sm="6">-->
<!--                  <v-text-field label="参考编号" placeholder="Placeholder" outlined dense></v-text-field>-->
<!--                </v-col>-->
<!--                <v-col cols="12" md="4" sm="6">-->
<!--                  <v-text-field label="总件数" type="number" placeholder="Placeholder" outlined dense></v-text-field>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--              <v-row>-->
<!--                <v-col cols="12" md="4" sm="6">-->
<!--                  <v-text-field label="预报实重" type="number" placeholder="Placeholder" outlined dense></v-text-field>-->
<!--                </v-col>-->
<!--                <v-col cols="12" md="4" sm="6">-->
<!--                  <v-text-field label="预报材重" type="number" placeholder="Placeholder" outlined dense></v-text-field>-->
<!--                </v-col>-->
<!--                <v-col cols="12" md="4" sm="6">-->
<!--                  <v-autocomplete-->
<!--                    v-model="value"-->
<!--                    :items="sltcustomers"-->
<!--                    dense-->
<!--                    outlined-->
<!--                    label="货物类型"-->
<!--                  ></v-autocomplete>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--              <v-row>-->
<!--                <v-col cols="12" md="4" sm="6">-->
<!--                  <v-autocomplete-->
<!--                    v-model="value"-->
<!--                    :items="sltcustomers"-->
<!--                    dense-->
<!--                    outlined-->
<!--                    label="包装类型"-->
<!--                  ></v-autocomplete>-->
<!--                </v-col>-->
<!--                <v-col cols="12" md="4" sm="6">-->
<!--                  <v-text-field label="中文品名" placeholder="Placeholder" outlined dense></v-text-field>-->
<!--                </v-col>-->
<!--                <v-col cols="12" md="4" sm="6">-->
<!--                  <v-text-field label="英文品名" placeholder="Placeholder" outlined dense></v-text-field>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--            </div>-->
<!--          </v-card>-->
<!--        </v-col>-->
<!--        <v-col cols="12" md="6" sm="6">-->
<!--          <v-card>-->
<!--            <v-card-title>-->
<!--              <div class="header">付款/其他</div>-->
<!--            </v-card-title>-->
<!--            <div class="form other">-->
<!--              <v-row>-->
<!--                <v-col cols="12" md="4" sm="6">-->
<!--                  <v-text-field label="Outlined" placeholder="Placeholder" outlined dense></v-text-field>-->
<!--                </v-col>-->
<!--                <v-col cols="12" md="4" sm="6">-->
<!--                  <v-text-field label="Outlined" placeholder="Placeholder" outlined dense></v-text-field>-->
<!--                </v-col>-->
<!--                <v-col cols="12" md="4" sm="6">-->
<!--                  <v-text-field label="Outlined" placeholder="Placeholder" outlined dense></v-text-field>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--              <v-row>-->
<!--                <v-col cols="12" md="4" sm="6">-->
<!--                  <v-text-field label="Outlined" placeholder="Placeholder" outlined dense></v-text-field>-->
<!--                </v-col>-->
<!--                <v-col cols="12" md="4" sm="6">-->
<!--                  <v-text-field label="Outlined" placeholder="Placeholder" outlined dense></v-text-field>-->
<!--                </v-col>-->
<!--                <v-col cols="12" md="4" sm="6">-->
<!--                  <v-text-field label="Outlined" placeholder="Placeholder" outlined dense></v-text-field>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--              <v-row>-->
<!--                <v-col cols="12" md="8" sm="6">-->
<!--                  <v-textarea-->
<!--                    outlined-->
<!--                    name="input-7-4"-->
<!--                    label="备注"-->
<!--                    rows="4"-->
<!--                    value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."-->
<!--                  ></v-textarea>-->
<!--                </v-col>-->
<!--                <v-col cols="12" md="4" sm="6">-->
<!--                  <v-text-field label="Outlined" placeholder="Placeholder" outlined dense></v-text-field>-->
<!--                  <v-text-field label="Outlined" placeholder="Placeholder" outlined dense></v-text-field>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--            </div>-->
<!--          </v-card>-->
<!--        </v-col>-->
<!--      </v-row>-->
      <v-row>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                            v-model="accountinfo.x_waybill_no"
                            :rules="[vrules.required]"
                            placeholder="运单流水号"
                            persistent-placeholder
                            dense
                            outlined
                    >
                      <template v-slot:label>
                        运单流水号
                        <span class="red--text">*</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                            v-model="accountinfo.x_send_no"
                            :rules="[vrules.required]"
                            placeholder="派车单号"
                            persistent-placeholder
                            dense
                            outlined
                    >
                      <template v-slot:label>
                        派车单号
                        <span class="red--text">*</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                            v-model="accountinfo.x_shift"
                            :rules="[vrules.required]"
                            placeholder="班次"

                            persistent-placeholder
                            dense
                            outlined
                    >
                      <template v-slot:label>
                        班次
                        <span class="red--text">*</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-select
                            v-model="accountinfo.x_oil_id"
                            :items="oillist"
                            item-text="x_oil_name"
                            item-value="x_oil_id"
                            :rules="[v => !!v || '请选择邮路']"
                            dense
                            outlined
                            label="请选择邮路"
                            required
                    >
                      <template v-slot:label>
                        邮路
                        <span class="red--text">*</span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                            v-model="accountinfo.x_vehicle_id"
                            :items="teamlist"
                            item-text="x_license_plate_nu"
                            item-value="x_vehicle_id"
                            :rules="[v => !!v || '请选择车辆']"
                            dense
                            outlined
                            label="请选择车辆"
                            required
                    >
                      <template v-slot:label>
                        车辆
                        <span class="red--text">*</span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                            v-model="accountinfo.x_freight"
                            placeholder="运费"
                            persistent-placeholder
                            dense
                            outlined
                    >
                      <template v-slot:label>
                        运费
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                            v-model="accountinfo.x_traffic"
                            placeholder="运输量"
                            persistent-placeholder
                            dense
                            outlined
                    >
                      <template v-slot:label>
                        运输量
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                            v-model="accountinfo.x_run_time"
                            placeholder="运行时长"
                            persistent-placeholder
                            dense
                            outlined
                    >
                      <template v-slot:label>
                        运行时长
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-menu
                            ref="menu01"
                            v-model="menu01"
                            :close-on-content-click="false"
                            :return-value.sync="accountinfo.x_approach_time"
                            transition="scale-transition"
                            min-width="auto"
                            offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                                v-model="accountinfo.x_approach_time"
                                label="	进场时间"
                                placeholder="进场时间"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dense
                                outlined
                                clearable
                        >
                          <template v-slot:label>进场时间</template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                              v-model="accountinfo.x_approach_time"
                              no-title
                              value="YYYY-mm-dd"
                              scrollable
                              locale="zh-cn"
                              @input="
                                                $refs.menu01.save(accountinfo.x_approach_time);
                                                menu01 = false;
                                              "
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4">
                    <v-menu
                            ref="menu04"
                            v-model="menu04"
                            :close-on-content-click="false"
                            :return-value.sync="accountinfo.x_departure_time"
                            transition="scale-transition"
                            min-width="auto"
                            offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                                v-model="accountinfo.x_departure_time"
                                label="	发车时间"
                                placeholder="发车时间"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dense
                                outlined
                                clearable
                        >
                          <template v-slot:label>发车时间</template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                              v-model="accountinfo.x_departure_time"
                              no-title
                              value="YYYY-mm-dd"
                              scrollable
                              locale="zh-cn"
                              @input="
                                                    $refs.menu04.save(accountinfo.x_departure_time);
                                                    menu04 = false;
                                                  "
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4">
                    <v-menu
                            ref="menu02"
                            v-model="menu02"
                            :close-on-content-click="false"
                            :return-value.sync="accountinfo.x_arrive_time"
                            transition="scale-transition"
                            min-width="auto"
                            offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                                v-model="accountinfo.x_arrive_time"
                                label="	到达时间"
                                placeholder="到达时间"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dense
                                outlined
                                clearable
                        >
                          <template v-slot:label>到达时间</template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                              v-model="accountinfo.x_arrive_time"
                              no-title
                              value="YYYY-mm-dd"
                              scrollable
                              locale="zh-cn"
                              @input="
                                                $refs.menu02.save(accountinfo.x_arrive_time);
                                                menu02 = false;
                                              "
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4">
                    <v-menu
                            ref="menu03"
                            v-model="menu03"
                            :close-on-content-click="false"
                            :return-value.sync="accountinfo.x_waybill_no_time"
                            transition="scale-transition"
                            min-width="auto"
                            offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                                v-model="accountinfo.x_waybill_no_time"
                                label="	运单流水号日期"
                                placeholder="运单流水号日期"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dense
                                outlined
                                clearable
                        >
                          <template v-slot:label>运单流水号日期</template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                              v-model="accountinfo.x_waybill_no_time"
                              no-title
                              value="YYYY-mm-dd"
                              scrollable
                              locale="zh-cn"
                              @input="
                                                $refs.menu03.save(accountinfo.x_waybill_no_time);
                                                menu03 = false;
                                              "
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                        <v-btn
                                color="warning"
                                dark
                                large
                                width="200px"
                                @click="saveaccount"
                        >提交运单
                        </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-form>
      </v-row>

    </v-container>
  </v-app>
</template>

<script>
  import _ from "underscore";
export default {
  name: "OrdersCreate",
  data() {
    return {
      sltcustomers:["客户名称1","客户名称2"],
      headers: [
        { text: ["NO.", "序号"], value: "number" },
        { text: ["Description Of Goods", "英文品名"], value: "name_en" },
        { text: ["Description Of Goods", "中文品名"], value: "name_cn" },
        { text: ["Qty.", "数量"], value: "quantity" },
        { text: ["Units.", "计量单位"], value: "units" },
        { text: ["Unit Price", "单价"], value: "unit_price" },
        { text: ["Total", "总价"], value: "total_price" },
        { text: ["Currency", "币别"], value: "currency" },
        { text: ["HS Code", "海关编码"], value: "hscode" },
        { text: ["Use", "用途"], value: "use" },
        { text: ["Material", "材质"], value: "material" },
        { text: ["N.W", "净重"], value: "nw" },
        { text: ["G.W", "毛重"], value: "gw" },
        { text: ["Country", "原产地"], value: "country" },
        { text: ["PK NO.", "包装件数"], value: "pkno" },
        { text: ["Remark", "备注"], value: "remark" },
        { text: ["Operation", "操作"], value: "operation" }
      ],
      list: [{ number: 1, name_en: "", name_cn: "", quantity: "", units: "" }],
      valid: true,
      vrules: {
        required: (value) => !!value || "不能为空",
        username: (value) =>
                /[a-zA-Z0-9]{5,12}/.test(value) || "邮路只能包含字母数字，长度5-12位",
        password: (value) =>
                (value.length >= 6 && value.length <= 20) || "密码长度6-20位",
      },
      oillist: [],
      teamlist: [],
      accountinfo: {
        x_waybill_uuid:'',
        x_waybill_no: '',
        x_send_no: '',
        x_oil_id: '',
        x_vehicle_id: '',
        x_shift: '',
        x_approach_time: '',
        x_departure_time: '',
        x_arrive_time: '',
        x_run_time: '',
        x_traffic: '',
        x_freight: '',
        x_waybill_no_time: '',
      },
      menu01: false,
      menu02: false,
      menu03: false,
      menu04: false,
      modeltitle: "",
    };
  },
  created() {

    this.$api
            .doAPI({
              Action: "ldadmin_oillist",
              Data: {
                page: 1,
                limit:100,
              },
            })
            .then((res) => {
              this.$toast.clear();
              if (res.code === 0) {
                this.oillist = res.data.list;
              } else {
                this.$utils.alert(res.msg);
              }
            })
    this.$api
            .doAPI({
              Action: "ldadmin_vehiclelist",
              Data: {
                page: 1,
                limit:100,
              },
            })
            .then((res) => {
              this.valid=true
              this.$refs.form.resetValidation();
              this.$toast.clear();
              if (res.code === 0) {
                this.teamlist = res.data.list;
              } else {
                this.$utils.alert(res.msg);
              }
            })

  },
  activated() {
    this.valid=true
    this.$refs.form.resetValidation();
    if(this.$route.query.id){
      this.$api
              .doAPI({
                Action: "ldadmin_waybillone",
                Data: {
                  x_waybill_uuid:this.$route.query.id
                },
              })
              .then((res) => {
                this.$toast.clear();
                if (res.code === 0) {
                  this.accountinfo = _.pick(res.data.list[0], _.keys(this.accountinfo));
                } else {
                  this.$utils.alert(res.msg);
                }
              })

    }else{
      this.accountinfo = {
          x_waybill_uuid:'',
          x_waybill_no: '',
          x_send_no: '',
          x_oil_id: '',
          x_vehicle_id: '',
          x_shift: '',
          x_approach_time: '',
          x_departure_time: '',
          x_arrive_time: '',
          x_run_time: '',
          x_traffic: '',
          x_freight: '',
          x_waybill_no_time: '',
      };
    }
  },
  methods: {
    saveaccount() {
      if (!this.$refs.form.validate()) return;
      if (this.accountinfo.x_waybill_uuid) {
        this.$toast.loading({message: ""});
        this.$api
                .doAPI({
                  Action: "ldadmin_waybilledit",
                  Data: {...this.accountinfo},
                })
                .then((res) => {
                  this.$toast.clear();
                  if (res.code === 0) {
                    this.$router.push({path: '/orders/list', query: {id: 0}} )
                  } else {
                    this.$utils.alert(res.msg);
                  }
                })
                .catch((err) => {
                  this.$toast.clear();
                  console.error(err);
                });
      } else {
        this.$toast.loading({message: ""});
        this.$api
                .doAPI({
                  Action: "ldadmin_waybilladd",
                  Data: {...this.accountinfo},
                })
                .then((res) => {
                  this.$toast.clear();
                  if (res.code === 0) {
                    this.$router.push({path: '/orders/list', query: {id: 0}} )
                  } else {
                    this.$utils.alert(res.msg);
                  }
                })
                .catch((err) => {
                  this.$toast.clear();
                  console.error(err);
                });
      }

    },
    cancelsave() {
      this.$refs.form.resetValidation();
    },
  }
};
</script>
<style lang="less" scope>
.v-card__title {
  background: #f2f2f2;
  font-size: 14px;
  padding: 10px 16px;
}
.form {
  padding: 15px;
}
p.ph {
  margin: 0;
}
.cell {
  display: inline-block;
}
.cell p:first-child:before {
  content: "*";
  color: red;
  display: inline;
}
.txt{top: 13px;}
</style>

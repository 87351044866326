<template>
  <v-app id="inspire">
    <v-main>
      <div class="main">
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="7" class="main-part">
              <div class="d-flex"></div>
            </v-col>
            <v-col cols="12" lg="5" align="center" justify="center" class="login-part">
              <v-row no-gutters align="center" justify="center">
                <v-col
                  class="login-part d-flex"
                  align="center"
                  justify="center"
                  cols="12"
                >
                  <div class="login-wrapper">
                    <div class="tabBox">
                      <!-- <ul
                        class="scan-code account-tab js-tab clearfix"
                      >
                        <li
                          class="cutover code-cutover active"
                          v-show="logintype === 'qrcode'"
                        >
                          <img src="@/assets/image/login/win_code.png" class="win_code" />
                          <img src="@/assets/image/login/account.png" class="account" />
                        </li>
                        <li
                          class="cutover login-cutover active"
                          v-show="logintype === 'password'"
                        >
                          <img src="@/assets/image/login/coded.png" class="coded" />
                          <img src="@/assets/image/login/scan.png" class="scan" />
                        </li>
                      </ul> -->
                    </div>
                    <div class="container loginBox">
                      <v-form
                        v-show="logintype === 'password'"
                        ref="form"
                        v-model="valid"
                        lazy-validation
                      >
                        <p class="title">欢迎</p>
                        <p>请使用你的帐户登录</p>
                        <v-text-field
                          label="帐号"
                          v-model="username"
                          :rules="[vrules.required]"
                          dense
                        />
                        <v-text-field
                          label="密码"
                          type="password"
                          v-model="password"
                          :rules="[vrules.required]"
                          dense
                        />
                        <v-text-field
                          label="验证码"
                          v-model="code"
                          :rules="[vrules.required]"
                          dense
                          :loading="refreshing"
                        >
                          <template v-slot:append>
                            <v-img
                              :src="codeimg"
                              @click="refreshcode"
                              style="cursor: pointer"
                            ></v-img>
                          </template>
                        </v-text-field>
                        <!-- <p style="text-align:right;">
                          <a href="javascript:;">忘记密码？</a>
                        </p>-->
                        <v-btn
                          class="loginbtn"
                          color="primary"
                          :disabled="!valid"
                          @click="login"
                        >
                          登录
                        </v-btn>
                      </v-form>
                      <div v-show="logintype === 'qrcode'">
                        <v-img width="235" :src="qrimg">
                          <div
                            v-show="qrstatus === 'verifying'"
                            style="
                              width: 235px;
                              height: 235px;
                              background: hsla(0, 0%, 100%, 0.96);
                            "
                          >
                            <svg
                              width="150"
                              height="150"
                              viewBox="0 0 18 18"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18 9.111C18 13.971 13.97 18 8.889 18 4.029 18 0 13.97 0 9.111 0 4.03 4.03 0 8.889 0 13.97 0 18 4.03 18 9.111zM4.788 9.79l2.876 2.976c.041.043.109.047.15.007l6.327-6.227a.196.196 0 00-.006-.275l-.236-.233a.221.221 0 00-.291-.017l-5.792 4.825c-.037.032-.114.034-.161-.002L5.329 9.06c-.09-.068-.207-.043-.274.048l-.28.38a.234.234 0 00.013.3z"
                                fill="#09BB07"
                                fill-rule="evenodd"
                              />
                            </svg>
                            <p>扫码成功</p>
                            <p>请在微信中选择帐号登录</p>
                          </div>
                          <div
                            v-show="qrstatus === 'timeout'"
                            style="
                              width: 235px;
                              height: 235px;
                              background: hsla(0, 0%, 100%, 0.96);
                            "
                          >
                            <svg
                              viewBox="0 0 1024 1024"
                              xmlns="http://www.w3.org/2000/svg"
                              width="150"
                              height="150"
                            >
                              <path
                                d="M862.993016 148.089944a505.388985 505.388985 0 0 0-714.785319 0A505.608402 505.608402 0 0 0 863.066155 863.094679a505.462124 505.462124 0 0 0-0.146278-714.931597zM725.857799 663.937775a43.736992 43.736992 0 0 1-61.802271 61.802271L505.41751 567.394582 347.145185 725.740046a43.736992 43.736992 0 0 1-61.802271-61.802271l158.491741-158.345464-158.491741-158.418602a43.736992 43.736992 0 0 1 61.802271-61.802271L505.41751 443.79004 663.909251 285.298299a43.736992 43.736992 0 0 1 61.802271 61.802271L567.292919 505.592311l158.56488 158.345464z"
                                fill="#F0441C"
                              />
                            </svg>
                            <p>扫码失败</p>
                            <p>请刷新后登录</p>
                          </div>
                        </v-img>
                        <p v-if="qrstatus === 'waiting'">微信扫一扫</p>
                        <!-- <v-btn
                          v-if="qrstatus === 'verifying'"
                          text
                          small
                          color="primary"
                          @click="refreshqr"
                        >
                          重新扫码
                        </v-btn> -->
                        <v-btn
                          v-if="qrstatus === 'timeout'"
                          text
                          small
                          color="error"
                          @click="refreshqr"
                        >
                          刷新
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-footer>
        <v-col class="text-center" cols="12">
          {{ new Date().getFullYear() }}
          <strong>@ 河南利滴物流有限公司 版权所有</strong>
        </v-col>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import _ from "underscore";
export default {
  name: "login",
  data() {
    return {
      valid: true,
      username: "",
      password: "",
      code: "",
      vrules: {
        required: (value) => !!value || "不能为空",
      },
      logintype: "password",
      codeuuid: "",
      codeimg: "",
      qruuid: "",
      qrimg: "",
      qrstatus: "",
      refreshing: false,
    };
  },
  watch: {
    username() {
      if (this.username) this.username_error = "";
    },
    password() {
      if (this.password) this.password_error = "";
    },
    logintype(logintype) {
      if (logintype === "qrcode") {
        this.refreshqr();
      } else {
        this.refreshcode();
        clearTimeout(window.__lidi_qrtimer__);
      }
    },
  },
  computed: {},
  methods: {
    togglelogin() {
      console.log("togglelogin", this.logintype);
      if (this.logintype === "password") {
        this.logintype = "qrcode";
      } else if (this.logintype === "qrcode") {
        this.logintype = "password";
      }
    },
    refreshcode() {
      if (this.refreshing) return;
      this.refreshing = true;
      this.$toast.loading({ message: "" });
      this.$api
        .post("/api/authcode")
        .then((res) => {
          console.log(res);
          this.codeuuid = res.uuid;
          this.codeimg = res.img;
          this.$toast.clear();
          this.refreshing = false;
        })
        .catch((err) => {
          this.$toast.clear();
          this.refreshing = false;
        });
    },
    refreshqr() {
      this.$toast.loading({ message: "" });
      this.$api
        .post("/api/authqr", { from: "lidi" })
        .then((res) => {
          console.log(res);
          if (res.uuid && res.img) {
            this.qruuid = res.uuid;
            this.qrimg = res.img;
            this.scanqr();
          }
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
        });
    },
    scanqr() {
      this.$api
        .post("/api/scanqr", { uuid: this.qruuid })
        .then((data) => {
          clearTimeout(window.__lidi_qrtimer__);
          this.qrstatus = data.status;
          this.qrdesc = data.desc;
          if (data.status === "waiting" || data.status === "verifying") {
            window.__lidi_qrtimer__ = setTimeout(() => {
              this.scanqr();
            }, 1000);
          } else if (data.status === "timeout" || data.status === "canceled") {
            this.refreshqr();
          } else if (data.status === "logined") {
            localStorage.setItem("lidi.token", data.token);
            this.$store.dispatch("ClearTabs");
            this.$utils.setStoreAdmin(data);
            this.$router.push({ path: "/" }).catch(console.error);
          }
        })
        .catch((err) => {
          clearTimeout(window.__lidi_qrtimer__);
          window.__lidi_qrtimer__ = setTimeout(() => {
            this.scanqr();
          }, 1000);
        });
    },
    login() {
      if (!this.$refs.form.validate()) return;
      this.$toast.loading({ message: "正在登录" });
      this.$api
        .doAPI({
          Action: "ldadmin_login",
          Data: {
            username: this.username,
            password: this.password,
            uuid: this.codeuuid,
            code: this.code,
            lt: this.$route.query.lt,
          },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            localStorage.setItem("lidi.token", res.data.token);
            this.$store.dispatch("ClearTabs");
            this.$utils.setStoreAdmin(res.data);
            this.$router.replace({ path: "/" }).catch(console.error);
          } else {
            this.$utils.alert(res.msg);
          }
        })
        .catch((err) => {
          this.$toast.clear();
          this.$utils.alert("登录失败");
          console.error(err);
        });
    },
  },
  activated() {
    if (this.logintype === "qrcode") this.refreshqr();
    if (this.logintype === "password") this.refreshcode();
  },
};
</script>
<style lang="less" scoped>
.main::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #0055a6;
  opacity: 0.3;
  z-index: 1;
}
.main {
  background: url(~@/assets/image/bg.jpg) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  .main-part {
    font-family: Roboto, sans-serif;
    font-size: 30px;
    font-weight: 500;
    color: #fff;
    z-index: 3;
    p {
      text-align: center;
      font-size: 50px;
      line-height: 100px;
    }
    .d-flex {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }
  .login-part {
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    justify-content: flex-end;
    align-items: center;
    z-index: 3;
    p.title {
      text-align: center;
      font-family: Roboto, sans-serif;
      font-size: 60px;
      font-weight: 500;
    }
    .login-wrapper {
      width: 350px;
      height: 450px;
      background: #fff;
      border-radius: 10px;
      .regist {
        margin-top: 100px;
        a {
          text-decoration: underline;
        }
      }
      .loginBox {
        padding: 50px;
        .loginbtn {
          width: 100%;
          margin-top: 30px;
        }
      }
    }
  }
}

.v-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  z-index: 3;
}
.tabBox {
  height: 80px;
  ul {
    width: 100%;
    line-height: 60px;
    border-bottom: none;
    margin-top: 0;
    li {
      float: right;
      position: relative;
      font-family: "Microsoft YaHei";
      cursor: pointer;
      margin-right: 10px;
      img {
        float: right;
      }
    }
    li.active {
      color: #1b225d;
    }
  }
}
.header {
  position: absolute;
  z-index: 9;
  left: 0;
  right: 0;
  .container {
    padding: 5px 0 0 0;
  }
}
.theme--light.v-footer {
  background-color: unset;
  color: #fff;
}
p {
  margin: 0;
}
</style>

<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-row style="padding-top: 10px">
          <v-col cols="12" sm="12" md="3">
            <v-select
              v-model="type"
              :items="types"
              item-text="label"
              item-value="value"
              dense
              label="操作类型"
              :clearable="type !== ''"
              :menu-props="{ bottom: true, offsetY: true }"
            />
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <v-menu
              ref="datemenu"
              v-model="datemenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="date"
                  label="操作日期"
                  v-on="on"
                  dense
                  readonly
                  :value="date === '全部' ? '全部' : sdate"
                  :clearable="date !== '全部'"
                  persistent-placeholder
                />
              </template>
              <v-date-picker
                v-model="sdate"
                no-title
                @input="datemenu = false"
                locale="zh"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <v-select
              v-model="accountid"
              :items="allaccounts"
              item-text="name"
              item-value="_id"
              dense
              label="操作人"
              :clearable="accountid !== ''"
              :menu-props="{ bottom: true, offsetY: true }"
            />
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <v-btn dark style="width: 100%; height: 40px" color="teal" @click="search"
              >查询</v-btn
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="list"
          :items-per-page="limit"
          no-data-text="无数据"
          hide-default-footer
          disable-sort
          fixed-header
          height="600"
        >
          <template v-slot:item.create_time="{ item }">
            {{ item.create_time | dateTime("YYYY-MM-DD HH:mm") }}
          </template>
          <template v-slot:item.type="{ item }">
            <span>{{ typename(item.type) }}</span>
          </template>
          <!-- <template v-slot:item.actions="{ item }">
          <v-btn x-small color="success" @click="showdetail(item._id)">详情</v-btn>
          </template>-->
        </v-data-table>
        <v-pagination
          v-if="length > 1"
          v-model="page"
          :page="page"
          :length="length"
          total-visible="10"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import _ from "underscore";
export default {
  data() {
    return {
      types: [
        { value: "", label: "全部" },
        { value: "insert", label: "新增" },
        { value: "update", label: "修改" },
        { value: "remove", label: "删除" },
        { value: "download", label: "下载" },
        { value: "login", label: "登录" },
      ],
      type: "",
      date: "全部",
      sdate: "",
      datemenu: false,
      accounts: [],
      accountid: "",
      headers: [
        { text: "ID", value: "_id" },
        { text: "操作人", value: "accountname", align: "center" },
        { text: "操作类型", value: "type", align: "center" },
        { text: "操作内容", value: "action", align: "center" },
        { text: "操作时间", value: "create_time", align: "center" },
        // { text: "", value: "actions", align: "center" },
      ],
      list: [],
      page: 1,
      limit: 15,
      length: 0,
      searched: false,
    };
  },
  components: {},
  computed: {
    allaccounts() {
      return [{ _id: "", name: "全部" }, ...this.accounts];
    },
  },
  watch: {
    page() {
      this.getdata();
    },
    type(type) {
      if (!type) {
        this.type = "";
        if (this.searched) {
          this.searched = false;
          this.getdata();
        }
      }
    },
    accountid(accountid) {
      if (!accountid) {
        this.accountid = "";
        if (this.searched) {
          this.searched = false;
          this.getdata();
        }
      }
    },
    sdate(sdate) {
      if (sdate) this.date = sdate;
    },
    date(date) {
      if (!date) {
        this.date = "全部";
        this.sdate = "";
        if (this.searched) {
          this.searched = false;
          this.getdata();
        }
      }
    },
  },
  activated() {},
  mounted() {
    this.getdata();
  },
  methods: {
    typename(type) {
      return (_.findWhere(this.types, { value: type }) || { label: "未知" }).label;
    },
    search() {
      if (this.page !== 1) this.page = 1;
      else this.getdata();
      this.searched = true;
    },
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({
          Action: "ldadmin_systemlogs",
          Data: {
            page: this.page,
            limit: this.limit,
            type: this.type,
            date: this.date,
            accountid: this.accountid,
          },
        })
        .then((res) => {
          this.$toast.clear();
          // console.log(res);
          if (res.code === 0) {
            this.accounts = res.data.accounts;
            this.list = res.data.list;
            this.length = res.data.length;
            if (this.length > 0 && this.page > this.length) this.page = 1;
          } else {
            this.$alert(res.msg, { center: true, type: "warning" });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    showdetail(logId) {},
  },
};
</script>
<style scoped lang="less">
/deep/ .row {
  margin-bottom: 0 !important;
}
/deep/ .col-12 {
  padding-bottom: 0 !important;
}
/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
/deep/ .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 4px;
}
</style>

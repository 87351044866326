<template>
  <v-app id="PostAdd">
    <v-container fluid>
      <v-form
              ref="form"
              v-model="valid"
              lazy-validation
      >
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <v-card>
            <v-card-title>
              <div class="header">基本信息</div>
            </v-card-title>
            <div class="form basic">
              <v-row>
                <v-col cols="12" md="12" sm="6">
                  <v-text-field
                          v-model="form.name"
                          :rules="[v => !!v || '请输入名称']"
                          label="名称"
                          outlined
                          dense
                          required
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4" sm="6">
                  <v-select
                          v-model="form.upStream"
                          :items="upStreamList"
                          item-text="label"
                          item-value="value"
                          :rules="[v => !!v || '请选择上游信息']"
                          dense
                          outlined
                          label="上游信息"
                          required
                  />
                </v-col>
                <v-col cols="12" md="4" sm="6">
                  <v-autocomplete
                          v-model="form.downstream"
                          :items="downstreamList"
                          item-text="label"
                          item-value="value"
                          :rules="[v => !!v || '请选择下游信息']"
                          dense
                          outlined
                          label="下游信息"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field label="考核时效"  :rules="[v => !!v || '请输入考核时效']"  type="text" v-model="form.x_inspe_time"  placeholder="请输入考核时效" outlined dense></v-text-field>
                </v-col>
              </v-row>
<!--              <v-row>-->
<!--&lt;!&ndash;                <v-col cols="12" md="4" sm="6">&ndash;&gt;-->
<!--&lt;!&ndash;                  <v-text-field label="车辆成本" type="text" placeholder="Placeholder" outlined dense></v-text-field>&ndash;&gt;-->
<!--&lt;!&ndash;                </v-col>&ndash;&gt;-->
<!--                <v-col cols="12" md="4" sm="6">-->
<!--                  <v-text-field label="考核时效" type="text" v-model="form.x_inspe_time"  placeholder="请输入考核时效" outlined dense></v-text-field>-->
<!--                </v-col>-->
<!--              </v-row>-->
              <v-row>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field label="所属省份" type="text" v-model="form.x_province" placeholder="请输入所属省份" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field label="始发地"  v-model="form.x_proven" type="text" placeholder="请输入始发地" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="6">
                  <v-text-field label="目的地" v-model="form.x_destina" type="text" placeholder="请输入目的地" outlined dense></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <v-card>
            <v-card-actions>
              <v-btn
                      :disabled="autoUpdate"
                      :loading="isUpdating"
                      color="warning"
                      dark
                      large
                      width="200px"
                      @click="isUpdating = true"
              >提交运单
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      </v-form>
    </v-container>
  </v-app>
</template>
<script>
import _ from "underscore";
export default {
  name: "PostAdd",
  data() {
    return {
      upStreamList:[{
        label:'邮政中心局',
        value:1
      },{
        label:'地方分局',
        value:2
      },],
      downstreamList:[{
        label:'车队信息',
        value:1
      },{
        label:'车队信息2',
        value:2
      },],
      autoUpdate:false,
      form:{
        name:'',
        upStream:''
      },
      valid: true,
      headers: [
        { text: ["NO.", "序号"], value: "number" },
        { text: ["Description Of Goods", "英文品名"], value: "name_en" },
        { text: ["Description Of Goods", "中文品名"], value: "name_cn" },
        { text: ["Qty.", "数量"], value: "quantity" },
        { text: ["Units.", "计量单位"], value: "units" },
        { text: ["Unit Price", "单价"], value: "unit_price" },
        { text: ["Total", "总价"], value: "total_price" },
        { text: ["Currency", "币别"], value: "currency" },
        { text: ["HS Code", "海关编码"], value: "hscode" },
        { text: ["Use", "用途"], value: "use" },
        { text: ["Material", "材质"], value: "material" },
        { text: ["N.W", "净重"], value: "nw" },
        { text: ["G.W", "毛重"], value: "gw" },
        { text: ["Country", "原产地"], value: "country" },
        { text: ["PK NO.", "包装件数"], value: "pkno" },
        { text: ["Remark", "备注"], value: "remark" },
        { text: ["Operation", "操作"], value: "operation" }
      ],
      list: [{ number: 1, name_en: "", name_cn: "", quantity: "", units: "" }]
    };
  },
  methods: {

  },
  activated() {

  },
  watch: {

  },
  components: {

  }
};
</script>
<style lang="less" scoped>

</style>

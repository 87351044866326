<template>
  <v-container fluid>
    <v-btn color="primary" class="mb-2" @click="showadd">
      <v-icon dark>mdi-plus</v-icon>新增账号
    </v-btn>
    <v-btn color="primary" outlined class="ml-2 mb-2" @click="getdata">
      <v-icon dark>mdi-reload</v-icon>刷新
    </v-btn>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="limit"
        no-data-text="无数据"
        hide-default-footer
        disable-sort
      >
        <template v-slot:item.enable="{ item }">
          <v-checkbox :input-value="item.enable" hide-details="auto" readonly dense />
        </template>
        <template v-slot:item.username="{ item }">
          {{ item.username }}
          <v-chip label small color="green" dark v-if="item.current">当前</v-chip>
        </template>
        <template v-slot:item.role="{ item }">
          <v-chip label small :color="rolecolors[item.role]" dark>
            {{ rolenames[item.role] }}
          </v-chip>
        </template>
        <template v-slot:item.action="{ item }">
          <!-- <v-btn text small color="primary" @click="showverifycode(item._id)">
            校验码
          </v-btn> -->
          <v-btn
            text
            small
            color="success"
            @click="showedit(item)"
            v-if="
              (role === 'root' && item.role !== 'root') ||
              (role === 'admin' && item.role !== 'admin')
            "
          >
            编辑
          </v-btn>
          <a-popconfirm
            title="删除账号"
            ok-text="确定"
            cancel-text="取消"
            @confirm="removeaccount(item._id)"
          >
            <v-btn
              text
              small
              color="error"
              v-if="
                (role === 'root' && item.role !== 'root') ||
                (role === 'admin' && item.role !== 'root' && item._id !== userid)
              "
            >
              删除
            </v-btn>
          </a-popconfirm>
        </template>
      </v-data-table>
      <v-pagination
        v-if="length > 1"
        v-model="page"
        :page="page"
        :length="length"
        total-visible="10"
      />
    </v-card>
    <v-dialog v-model="model" persistent max-width="400px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title
            class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between"
          >
            {{ modeltitle }}
            <v-icon @click="cancelsave">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="accountinfo.username"
                    :rules="[vrules.required, vrules.username]"
                    placeholder="输入登录账号"
                    :disabled="accountinfo._id !== ''"
                    persistent-placeholder
                    dense
                    outlined
                  >
                    <template v-slot:label>
                      账号
                      <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-model="accountinfo.password"
                    label="密码"
                    placeholder="输入登录密码"
                    persistent-placeholder
                    :rules="
                      accountinfo._id === '' ? [vrules.required, vrules.password] : []
                    "
                    dense
                    outlined
                  >
                    <template v-slot:label>
                      密码
                      <span v-if="accountinfo._id === ''" class="red--text">*</span>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-model="accountinfo.name"
                    :rules="[vrules.required]"
                    placeholder="输入姓名"
                    persistent-placeholder
                    dense
                    outlined
                  >
                    <template v-slot:label>
                      姓名
                      <span class="red--text">*</span>
                    </template>
                  </v-text-field>
                  <!-- <v-text-field
                    v-model="accountinfo.mobile"
                    placeholder="输入手机号"
                    persistent-placeholder
                    dense
                    outlined
                  >
                    <template v-slot:label>
                      手机号
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-model="accountinfo.email"
                    placeholder="输入邮箱地址"
                    persistent-placeholder
                    dense
                    outlined
                  >
                    <template v-slot:label>
                      邮箱
                    </template>
                  </v-text-field> -->
                  <v-select
                    :items="role === 'root' ? roles2 : roles"
                    v-model="accountinfo.role"
                    :rules="[vrules.required]"
                    placeholder="选择角色"
                    persistent-placeholder
                    :menu-props="{ bottom: true, offsetY: true }"
                    dense
                    outlined
                  >
                    <template v-slot:label>
                      角色
                      <span class="red--text">*</span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="!valid" @click="saveaccount">确定</v-btn>
            <v-btn @click="cancelsave">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- <v-dialog v-model="model2" persistent max-width="400px">
      <v-card>
        <v-card-title
          class="grey lighten-4 pa-2 text-subtitle-1 mb-4 d-flex justify-space-between"
        >
          校验码
          <v-icon @click="model2 = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-subheader class="text-h3 justify-center">
              {{ verifycode }}
            </v-subheader>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <span>注：用于小程序用户绑定验证</span>
          <v-spacer></v-spacer>
          <v-btn @click="model2 = false">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-container>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";
export default {
  name: "SystemAccount",
  data() {
    return {
      valid: true,
      headers: [
        { text: "账号", value: "username" },
        { text: "姓名", value: "name", align: "center" },
        // { text: "手机号", value: "mobile", align: "center" },
        // { text: "邮箱", value: "email", align: "center" },
        { text: "角色", value: "role", align: "center" },
        { text: "操作", value: "action", width: 180 },
      ],
      page: 1,
      limit: 15,
      list: [],
      length: 0,
      model: false,
      modeltitle: "",
      vrules: {
        required: (value) => !!value || "不能为空",
        username: (value) =>
          /[a-zA-Z0-9]{5,12}/.test(value) || "账号只能包含字母数字，长度5-12位",
        password: (value) =>
          (value.length >= 6 && value.length <= 20) || "密码长度6-20位",
      },
      accountinfo: {
        _id: "",
        username: "",
        password: "",
        name: "",
        mobile: "",
        email: "",
        role: "",
      },
      model2: false,
      verifycode: "",
    };
  },
  computed: {
    ...mapState(["role", "userid", "roles", "roles2", "rolecolors", "rolenames"]),
  },
  components: {},
  watch: {
    page() {
      this.getdata();
    },
  },
  activated() {
    this.page = 1;
    this.list = [];
    this.length = 0;
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({
          Action: "ldadmin_accounts",
          Data: { page: this.page, limit: this.limit },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.list = res.data.list;
            this.length = res.data.length;
            if (this.length > 0 && this.page > this.length) this.page = 1;
          } else {
            this.$utils.alert(res.msg);
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    showadd() {
      this.accountinfo = {
        _id: "",
        username: "",
        password: "",
        name: "",
        mobile: "",
        email: "",
        role: "",
      };
      this.modeltitle = "新增账号";
      this.model = true;
    },
    showedit(item) {
      this.accountinfo = _.pick(item, _.keys(this.accountinfo));
      this.modeltitle = "编辑账号";
      this.model = true;
    },
    saveaccount() {
      if (!this.$refs.form.validate()) return;
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({
          Action: "ldadmin_saveaccount",
          Data: { accountinfo: this.accountinfo },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.model = false;
            this.getdata();
          } else {
            this.$utils.alert(res.msg);
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    cancelsave() {
      this.$refs.form.resetValidation();
      this.model = false;
    },
    // showverifycode(id) {
    //   this.$toast.loading({ message: "" });
    //   this.$api
    //     .doAPI({
    //       Action: "ldadmin_showverifycode",
    //       Data: { id },
    //     })
    //     .then((res) => {
    //       this.$toast.clear();
    //       if (res.code === 0) {
    //         this.verifycode = res.data.verifycode;
    //         this.model2 = true;
    //       } else {
    //         this.$utils.alert(res.msg);
    //       }
    //     })
    //     .catch((err) => {
    //       this.$toast.clear();
    //       console.error(err);
    //     });
    // },
    removeaccount(id) {
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({
          Action: "ldadmin_removeaccount",
          Data: { id },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.getdata();
          } else {
            this.$utils.alert(res.msg);
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .row {
  margin-bottom: 0 !important;
}
/deep/ .col-12 {
  padding-bottom: 0 !important;
}
/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
/deep/ .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 4px;
}
</style>

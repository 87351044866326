import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
import store from '../store';
import api from '../request'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({
  easing: 'ease',  // 动画方式
  speed: 500,  // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3 // 初始化时的最小百分比
});

import WorkBench from "@/views/WorkBench.vue";

import Login from "@/views/Login.vue";
import NotFound from "@/views/404.vue";

import PostList from "@/views/post/list.vue";
import PostAdd from "@/views/post/add.vue";

import OrdersCreate from "@/views/orders/create.vue";
import OrdersList from "@/views/orders/list.vue";
import OrdersWayBill from "@/views/orders/waybill.vue";

import DriverList from "@/views/driver/list.vue";
import Motorcade from "@/views/driver/motorcade.vue";

import VehicleList from "@/views/vehicle/list.vue";

import ContractList from "@/views/contract/list.vue";
import ContractAdd from "@/views/contract/add.vue";
import ContractInfo from "@/views/info.vue";

import system_account from '@/views/system/account.vue';
import system_roles from '@/views/system/roles.vue';
import system_log from '@/views/system/log.vue';
import user_setting from '@/views/user/setting.vue';

const routes = [
  { path: "/", redirect: "/workbench", },
  //工作台
  { path: "/workbench", component: WorkBench, meta: { layout: "basic-layout", title: "利滴物流大数据", }, },
  //邮路信息管理
  { path: "/post/list", component: PostList, meta: { layout: "basic-layout", title: "邮路管理", }, },
  //添加邮路
  { path: "/post/add", component: PostAdd, meta: { layout: "basic-layout", title: "新增邮路", }, },
  //运单管理
  //运单列表
  { path: "/orders/list", component: OrdersList, meta: { layout: "basic-layout", title: "运单列表" }, },
  //创建运单
  { path: "/orders/create", component: OrdersCreate, meta: { layout: "basic-layout", title: "新增运单" }, },
  //运单报表
  { path: "/orders/waybill", component: OrdersWayBill, meta: { layout: "basic-layout", title: "运单报表" }, },
  //司机管理
  //司机列表
  { path: "/driver/list", component: DriverList, meta: { layout: "basic-layout", title: "司机列表" }, },
  //车队列表
  { path: "/driver/motorcade", component: Motorcade, meta: { layout: "basic-layout", title: "车队列表" }, },
  //车辆信息管理
  //车辆列表
  { path: "/vehicle/list", component: VehicleList, meta: { layout: "basic-layout", title: "车辆列表" }, },
  //电子合同管理
  //电子合同列表
  { path: "/contract/list", component: ContractList, meta: { layout: "basic-layout", title: "电子合同列表" }, },
  //添加电子合同
  { path: "/contract/add/:id?", component: ContractAdd, meta: { layout: "basic-layout", title: "添加电子合同" }, },
  //电子合同
  { path: "/contracts/:id?", component: ContractInfo, meta: { layout: 'empty-layout', requireAuth: false, storeable: false } },
  //系统设置
  //账户管理
  { path: '/system/account', component: system_account, meta: { layout: 'basic-layout', title: '账户管理', storeable: false } },
  { path: '/system/roles', component: system_roles, meta: { layout: 'basic-layout', title: '权限管理', storeable: false } },
  //操作日志
  { path: '/system/log', component: system_log, meta: { layout: 'basic-layout', title: '操作日志', storeable: false } },
  //账户设置
  { path: '/user/setting', component: user_setting, meta: { layout: 'basic-layout', title: '账户设置', storeable: false } },
  //登录
  { path: '/login', component: Login, meta: { layout: 'empty-layout', requireAuth: false, storeable: false } },
  //404
  { path: '/404', component: NotFound, meta: { layout: 'empty-layout', requireAuth: false, storeable: false } },
  { path: "*", redirect: "/404", },
];

const router = new Router({
  mode: "history",
  routes,
  base: "/",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) store.dispatch('SetNavTitle', to.meta.title);
  // console.log(to, from);
  console.log(to.path);
  store.dispatch('SetNavLogo', '');
  // 每次切换页面时，调用进度条
  NProgress.start();
  let v = localStorage.getItem("lidi.version") || "0";
  api.get(`/api/initld?t=${Date.now()}&v=${v}`).then(data => {
    // console.log(data);
    if (data.code === "newversion") {
      localStorage.setItem("lidi.version", data.version);
      NProgress.done()
      window.location.href = to.fullPath;
      return;
    }
    setTimeout(() => {
      let { userid, username, name, role, needlogin } = data;
      if (userid) {
        localStorage.setItem("lidi.userid", userid);
        store.dispatch("SetUserid", userid);
      }
      if (username) {
        localStorage.setItem("lidi.username", username);
        store.dispatch("SetUsername", username);
      }
      if (name) {
        localStorage.setItem("lidi.name", name);
        store.dispatch("SetName", name);
      }
      if (role) {
        localStorage.setItem("lidi.role", role);
        store.dispatch("SetRole", role);
      }
      if (needlogin) localStorage.clear();
      if (!localStorage.getItem('lidi.token') && ((to.meta && to.meta.requireAuth !== false))) return next({ path: '/login' });
      if (localStorage.getItem('lidi.token') && (to.path === '/login')) return next({ path: '/' });
      if (to.meta.storeable !== false) store.dispatch("OpenTab", { path: to.path, name: to.meta.title });
      next();
    }, 0);
  });
});
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})
window._doRouterPush = (path) => {
  if (router.currentRoute.path === path) return;
  router.push({ path }).catch(console.error);
}
window._doRouterReplace = (path) => {
  if (router.currentRoute.path === path) return;
  router.replace({ path }).catch(console.error);
}

export default router;
